import { environment } from '../../../environments/environment';

export const applicationUrls = {
  authentication: {
    create: 'create-permission',
    read: 'read-permissions',
    update: 'update-permission',
    delete: 'delete-permission',
  },
  authorization: {
    create: 'create-permission',
    read: 'read-permissions',
    update: 'update-permission',
    delete: 'delete-permission',
  },
  user: {
    login: environment.baseApiUrl + 'api/auth/signin/',
    create: environment.baseApiUrl + 'user/',
    read: environment.baseApiUrl + 'user/all/',
    readById: environment.baseApiUrl + 'user/find/',
    update: environment.baseApiUrl + 'user/',
    updateUserProfile: environment.baseApiUrl + 'update-user-profile/',
    delete: environment.baseApiUrl + 'user/delete/',
    readByDepartmentId: environment.baseApiUrl + 'data/users-by-department',
    readUsers: environment.baseApiUrl + 'users',
    readUserById: environment.baseApiUrl + 'user/',
    getUserProfile: environment.baseApiUrl + 'get-user-profile/',
    readUserByParams: environment.baseApiUrl + 'findUsers',
    register: environment.baseApiUrl + 'selfRegistration',
    createStpUser: environment.stpRegistrationApiUrl + 'registration/stp-users',
    createNewUserByStp: environment.baseApiUrl + 'new-stp-user',
    saveNewStpUserByStp: environment.stpRegistrationApiUrl + 'registration/save-new-stp-user',
    forgotpassword: environment.baseApiUrl + 'forgot-password',
    resetpassword: environment.baseApiUrl + 'reset-password',
    getStpUserByStpMainId: environment.stpRegistrationApiUrl + 'registration/get-stp-user-by-stp-main-id',
    getAllUserDetailsInfo:
      environment.stpRegistrationApiUrl +
      'get-all-users-details-info',
    getUserApplicationStatusInfo:
      environment.stpRegistrationApiUrl +
      'registration/check-stp-application-status',
    getUserById:
      environment.stpRegistrationApiUrl + 'registration/get-users-by-id',
    getStpUsers: environment.stpRegistrationApiUrl + 'registration/get-all-users-by-stp',
    getStpUsersByStp: environment.stpRegistrationApiUrl + 'registration/get-stp-users-by-stp',
    getStpUsersInfoReportByStp: environment.stpRegistrationApiUrl + 'registration/get-stp-users-report-by-stp',
    getUsersByIds: environment.baseApiUrl + 'find-users-by-ids',
    getStpUserByUserId: environment.stpRegistrationApiUrl + 'registration/get-stpUser-by-userId',
    updateStpUser: environment.stpRegistrationApiUrl + 'registration/update-stp-user',
    changeUserStatus: environment.baseApiUrl + 'change-user-status',
    getAclStpUserByUserId: environment.baseApiUrl + 'get-stp-user-by-id',
    readStpMainInfoByStpMainIds: environment.stpRegistrationApiUrl + "get-stpInfo-by-stpMainIds",
    getStpUserInfo: environment.stpRegistrationApiUrl + 'get-stp-user-info'
  },
  permission: {
    create: environment.baseApiUrl + 'permission/',
    read: environment.baseApiUrl + 'permission/',
    readById: environment.baseApiUrl + 'user/find/',
    update: environment.baseApiUrl + 'permission/',
    delete: environment.baseApiUrl + 'permission/',
    readByDepartmentId: environment.baseApiUrl + 'data/users-by-department',
    getPermissionListByFeatureId: environment.baseApiUrl + 'permission/get-permission-list-by-feature-id/',
  },
  module: {
    create: environment.baseApiUrl + 'module',
    read: environment.baseApiUrl + 'module/',
    readById: environment.baseApiUrl + 'module/',
    update: environment.baseApiUrl + 'module/',
    delete: environment.baseApiUrl + 'module/',
    readByDepartmentId: environment.baseApiUrl + 'data/users-by-department',
  },
  userVerification: {
    readById: environment.baseApiUrl + 'user-activation',
    checkEmailExist: environment.baseApiUrl + 'check-email-exist',
    checkMobileNoExist: environment.baseApiUrl + 'check-mobile-no-exist',
    checkEmailWithIdExist: environment.baseApiUrl + 'check-email-with-id-exist',
    checkEmailWithProfileExist: environment.baseApiUrl + 'check-email-with-profile-exist',
    checkPhoneWithProfileExist: environment.baseApiUrl + 'check-phone-with-profile-exist',
    checkStpNameExist:
      environment.stpRegistrationApiUrl + 'registration/check-stp-name-exist',
  },
  permissionType: {
    create: environment.baseApiUrl + 'permission/',
    read: environment.baseApiUrl + 'permissionType/',
    readById: environment.baseApiUrl + 'user/find/',
    update: environment.baseApiUrl + 'user/update/',
    delete: environment.baseApiUrl + 'user/delete/',
    readByDepartmentId: environment.baseApiUrl + 'data/users-by-department/',
  },
  role: {
    create: environment.baseApiUrl + 'role',
    read: environment.baseApiUrl + 'role/',
    readById: environment.baseApiUrl + 'role/',
    update: environment.baseApiUrl + 'role/',
    delete: environment.baseApiUrl + 'role/',
    saveRolePermission: environment.baseApiUrl + 'saveRolePermission/',
    readByDepartmentId: environment.baseApiUrl + 'data/users-by-department',
  },
  feature: {
    getFeatureList: environment.baseApiUrl + 'feature-list/get-feature-list',
    getFeatureListByModuleId: environment.baseApiUrl + 'feature-list/get-feature-list-by-module-id/'
  },
  userLocation: {
    readDivisions: environment.stpRegistrationApiUrl + 'divisions',
    readDistricts: environment.stpRegistrationApiUrl + 'districts',
    readDistrictsByParams: environment.baseApiUrl + 'districtsByDivisionIds',
    readUpazilas: environment.stpRegistrationApiUrl + 'upazillas',
    readUserLocation: environment.baseApiUrl + 'getUserLocation/',
  },
  designation: {
    create: environment.baseApiUrl + 'designation',
    read: environment.baseApiUrl + 'designations',
    readById: environment.baseApiUrl + 'designation/',
    update: environment.baseApiUrl + 'designation/',
    delete: environment.baseApiUrl + 'designation/',
  },
  basicProfile: {
    stpUserName: environment.stpRegistrationApiUrl + 'registration/stpusername',
    readStpType: environment.stpRegistrationApiUrl + 'stp-types',
    readStpOwnerType: environment.stpRegistrationApiUrl + 'owner-types/',
    readStpOwnerShipType: environment.stpRegistrationApiUrl + 'ownership-types',
    readStpMinistry: environment.stpRegistrationApiUrl + 'ministries',
    readInspectionOfficers: environment.stpRegistrationApiUrl + 'inspection-officer/get-inspection-officers-list',
    readStpMinistryByOwnershipId:
      environment.stpRegistrationApiUrl + 'ministry/ownership-type/',
    readStpAgency: environment.stpRegistrationApiUrl + 'agencies',
    readStpAgencyByMinistryId: environment.stpRegistrationApiUrl + 'agency/',
    readStpLocation: environment.stpRegistrationApiUrl + 'locations/',
    readStpPostOffice: environment.stpRegistrationApiUrl + 'post-offices/',
    readStpPostOfficeByUpazila:
      environment.stpRegistrationApiUrl + 'post-office/',
    readStpCountry: environment.stpRegistrationApiUrl + 'countries/',
    readStpDivision: environment.stpRegistrationApiUrl + 'country/divisions/',
    readStpDistrict: environment.stpRegistrationApiUrl + 'districts',
    readStpDistrictByDivisionId:
      environment.stpRegistrationApiUrl + 'division/',
    readStpUpazilla: environment.stpRegistrationApiUrl + 'upazillas/',
    readStpAppStatus: environment.stpRegistrationApiUrl + 'app-statuses/',
    readStpAppTypes: environment.stpRegistrationApiUrl + 'app-types/',
    readBasicProfileById: environment.stpRegistrationApiUrl + 'registration/basic-profile-by-id/',
    createBasicProfile:
      environment.stpRegistrationApiUrl + 'registration/basicprofile',
    updateBasicProfile:
      environment.stpRegistrationApiUrl + 'registration/basic-profile/update',
    mailingAfterFinalSubmit:
      environment.stpRegistrationApiUrl +
      'registration/basic-profile/profile/sent',
  },
  stpTrainer: {
    create: environment.stpRegistrationApiUrl + 'registration/stp-trainer',
    read: environment.stpRegistrationApiUrl + 'registration/stp-trainer/',
    readById: environment.stpRegistrationApiUrl + 'registration/stp-trainer/',
    update: environment.stpRegistrationApiUrl + 'registration/stp-trainer/',
    delete: environment.stpRegistrationApiUrl + 'registration/stp-trainer/',
    readOccupation:
      environment.stpRegistrationApiUrl +
      'registration/stp-trainer-occupation/',
    saveExcelData:
      environment.stpRegistrationApiUrl +
      'registration/stp-trainer/save-trainer-list',
  },
  infrastructure: {
    create:
      environment.stpRegistrationApiUrl + 'registration/stp-infrastructure',
    update:
      environment.stpRegistrationApiUrl +
      'registration/stp-infrastructure/update',
    readOwnershipStatus: environment.stpRegistrationApiUrl + 'building-type/',
    readConstructionType:
      environment.stpRegistrationApiUrl + 'ownership-status/',
  },
  payment: {
    create: environment.stpRegistrationApiUrl + 'payment',
    createOnlinePayment:
      environment.stpRegistrationApiUrl + 'stp-online-payment/',
    createOnline: environment.paymentUrl + 'payment/initiate/',
    readByStpMainId: environment.stpRegistrationApiUrl + 'stp-payment-details/',
    readPaymentInfo: environment.paymentUrl + 'payment/all-Payment-List',
    paymentSearchList: environment.paymentUrl + 'payment/payment-search-list',
    paymentExcelSearch:
      environment.paymentUrl + 'payment/get-all-payment-excel-data',
    paymentForCourseAccreditation:
      environment.stpRegistrationApiUrl + 'inspection/save-payment-inspection',
    getPaymentInfoForCourseAccByInspectionId:
      environment.stpRegistrationApiUrl + 'inspection/get-inspection',
  },
  paymentType: {
    readPaymentType:
      environment.paymentUrl + 'payment-type/all-Payment-Type-List',
  },
  furniture: {
    create: environment.stpRegistrationApiUrl + 'registration/stp-furniture',
    read: environment.stpRegistrationApiUrl + 'registration/stp-furnitures',
    readFurnitureById:
      environment.stpRegistrationApiUrl + 'registration/stp-furniture/',
    update: environment.stpRegistrationApiUrl + 'registration/stp-furniture/',
    delete: environment.stpRegistrationApiUrl + 'registration/stp-furniture/',
    readTrainerOccupations:
      environment.stpRegistrationApiUrl + 'registration/stp-trainer-occupation',
    saveExcelData:
      environment.stpRegistrationApiUrl +
      'registration/stp-furniture/save-all-furniture-list',
  },
  management: {
    read: environment.stpRegistrationApiUrl + 'registration/stp-staff',
    readById: environment.stpRegistrationApiUrl + 'registration/stp-staff/',
    delete: environment.stpRegistrationApiUrl + 'registration/stp-staff/',
    create: environment.stpRegistrationApiUrl + 'registration/stp-staff',
    update: environment.stpRegistrationApiUrl + 'registration/stp-staff/',
    saveExcelData:
      environment.stpRegistrationApiUrl +
      'registration/stp-management/save-management-staff-list',
  },
  tools: {
    read: environment.stpRegistrationApiUrl + 'registration/stp-tools',
    create: environment.stpRegistrationApiUrl + 'registration/stp-tools',
    delete: environment.stpRegistrationApiUrl + 'registration/stp-tools/',
    readById: environment.stpRegistrationApiUrl + 'registration/stp-tools/',
    update: environment.stpRegistrationApiUrl + 'registration/stp-tools/',
    saveExcelData:
      environment.stpRegistrationApiUrl +
      'registration/stp-tools/save-all-stptools-list',
  },
  unit: {
    read: environment.stpRegistrationApiUrl + 'units',
    readById: environment.stpRegistrationApiUrl + 'units',
  },
  applications: {
    readStpMain: environment.stpRegistrationApiUrl + 'stp-mains',
    read: environment.stpRegistrationApiUrl + 'mains',
    getAllRegisteredStp: environment.commonServiceApiUrl + 'common/get-all-registered-stp',
    getAllRegisteredStpWithoutPagination: environment.commonServiceApiUrl + 'common/get-all-registered-stp-without-pagination',
    getAllStpListViewExcelInfo: environment.stpRegistrationApiUrl + 'get-all-stp-list-view-excel-info',
    readById:
      environment.stpRegistrationApiUrl +
      'registration/getStpAppInfoByStpMain/',
    readByStpMainId: environment.stpRegistrationApiUrl + 'main/',
    stpNameUpdate:
      environment.stpRegistrationApiUrl + 'stpmain/application/update',
    updateStpOrganizationDetails:
      environment.stpRegistrationApiUrl + 'stpmain/organization-details/update',
    getStpOrganizationDetails:
      environment.stpRegistrationApiUrl + 'stpmain/organization-details/get',
    stpApprovalUpdate:
      environment.stpRegistrationApiUrl + 'registration/update-action-status',
    filteredStpApplication:
      environment.stpRegistrationApiUrl + 'stpmain/searching-stp-application',
    rollBackRequest:
      environment.stpRegistrationApiUrl + 'registration/rollBack-request',
    approveRejectStpUser:
      environment.stpRegistrationApiUrl +
      'registration/temporary-approve-reject-stp-users',
  },

  inspection: {
    saveScheduleInspection:
      environment.stpRegistrationApiUrl +
      'inspection-schedule-form/save-schedule-inspection',
    submitScheduleInspection:
      environment.stpRegistrationApiUrl +
      'inspection-schedule-form/submit-schedule-inspection',
    saveCompleteInspection:
      environment.stpRegistrationApiUrl +
      'inspection-report/save-complete-inspection-report',
    checkPhoneExist:
      environment.stpRegistrationApiUrl + 'inspection-officer/get-inspection-officer-by-phone',
    saveInspectorOfficerApprovalOnPreview: environment.stpRegistrationApiUrl + "inspection-report/inspector-officer-approval",
    saveInspectionOfficer: environment.stpRegistrationApiUrl + "inspection-officer/save-inspection-officer",
    checkInspectionOfficerByPhone: environment.stpRegistrationApiUrl + 'inspection-officer/check-inspection-officer-by-phone',
    getNSDAOfficer: environment.baseApiUrl + "get-all-users-by-tag",
    getInspectionOfficer: environment.stpRegistrationApiUrl + "inspection-officer/get-all-inspection-officers",
    getScheduleInspectionByInspectionId: environment.stpRegistrationApiUrl + "inspection-schedule-form/get-schedule-inspection-by-inspectionId",
    getStpSacInspectionPreviewByInspectionId: environment.stpRegistrationApiUrl + "inspection-registration-report/get-inspection-registration-report",
    getStpSacInspectionPreviewByEncryptedInspectionId: environment.stpRegistrationApiUrl + "inspection-registration-report/get-inspection-registration-report-preview",
    getCompleteInspectionByInspectionId: environment.stpRegistrationApiUrl + "inspection-report/get-complete-inspection-report-by-inspectionId",
    getCompleteInspectionByInspectionIdForVerifiedOtp: environment.stpRegistrationApiUrl + "inspection-report/get-complete-inspection-report-preview",
    getCompleteInspectionByInspectionType: environment.stpRegistrationApiUrl + "inspection-officer/get-all-inspection-officers-by-type",
    submitCompleteInspection: environment.stpRegistrationApiUrl + "inspection-report/submit-complete-inspection",
    previewApplication: environment.stpRegistrationApiUrl + "inspection/get-course-accreditation-data-by-inspectionResultId",
    readById: environment.stpRegistrationApiUrl + 'schedule-inspection/get-stp-app-info-by-stpMain/',
    deleteOccupation: environment.stpRegistrationApiUrl + "inspection/delete-course-accreditation-data-by-inspectionResultId/",
    deleteInspection: environment.stpRegistrationApiUrl + 'inspection/delete/',
    saveBasicInspection: environment.stpRegistrationApiUrl + "inspection/save-basic-inspection",
    getInspectionById: environment.stpRegistrationApiUrl + "inspection/get-inspection/",
    getAllInspections: environment.stpRegistrationApiUrl + "inspection/get-inspection/",
    saveInspectionCad: environment.stpRegistrationApiUrl + "inspection/save-inspection-cad/",
    savePaymentInspection: environment.stpRegistrationApiUrl + "inspection/save-payment-inspection/",
    getAllInspectionbyAclUserId: environment.stpRegistrationApiUrl + "inspection/get-all-inspections-by-aclUserId",
    getAllInspectionForAdmin: environment.stpRegistrationApiUrl + "inspection/get-all-inspections-for-admin",
    getSummaryOccupationListByInspectionId: environment.stpRegistrationApiUrl + "inspection/get-summary-occupation-list-by-inspectionId/",
    getCourseAccreditationByResultId: environment.stpRegistrationApiUrl + "inspection/get-course-accreditation-data-by-inspectionResultId/",
    getAllApplicationTypeByAccreditation: environment.stpRegistrationApiUrl + "inspection-application-type/get-all-inspection-application-by-type",
    getAllApplicationTypes: environment.stpRegistrationApiUrl + "inspection-application-type/get-all-inspection-application-types",
    submitApplication: environment.stpRegistrationApiUrl + "inspection/submit-course-accreditation-form/",
    rollbackInspectionToStp: environment.stpRegistrationApiUrl + "inspection/roll-back-inspection-to-stp",
    getAllApprovedInspectionResults: environment.stpRegistrationApiUrl + "inspection/get-all-approved-inspection-results",
    getAllApprovedInspectionResultsForCreateCourse: environment.stpRegistrationApiUrl + "inspection/get-all-approved-inspection-results-for-create-course",
    getAllApprovedInspectionResultsByStpMainId: environment.stpRegistrationApiUrl + "inspection/get-all-approved-inspection-results-by-stp-main-id",
    getAllApprovedOccupationsByStpMainId: environment.stpRegistrationApiUrl + "inspection/get-all-approved-occupations-by-stpMainId",
    getInspectionReportsRecommendationDetailsForDownload: environment.stpRegistrationApiUrl + "scrutiny-report/get-inspection-reports-recommendation-details-for-download",
    checkAllOccupationStatusByInspectionId: environment.stpRegistrationApiUrl + 'inspection/check-all-occupation-status-by-inspection-id/',
    getApprovedCourseCenter: environment.stpRegistrationApiUrl + 'inspection/get-approved-course-center',
    // getApprovedAssessmentCenter: environment.stpRegistrationApiUrl + 'inspection/get-approved-assessment-center',
    getApprovedTamInfoListByStpAndCourseCenterApprovalDate: environment.stpRegistrationApiUrl + 'inspection/get-approved-tam-info-list-by-stp-and-course-center-approval-date',

    inspectionApplicationType: {
      saveApplicationType:
        environment.stpRegistrationApiUrl + 'inspection-application-type/save',
      getApplicationTypeById:
        environment.stpRegistrationApiUrl +
        'inspection-application-type/get-inspection-application-type/',
      getAllApplicationType:
        environment.stpRegistrationApiUrl +
        'inspection-application-type/get-all-inspection-application-types',
    },
    inspectionOfficer: {
      saveInspectionOfficer: environment.stpRegistrationApiUrl + 'inspection-officer/save-inspection-officer',
      getInspectionOfficerById: environment.stpRegistrationApiUrl + 'inspection-officer/get-inspection-officer',
      getAllInspectionOfficers: environment.stpRegistrationApiUrl + 'inspection-officer/get-all-inspection-officers',
    },
    inspectionRecommendationType: {
      saveRecommendationType:
        environment.stpRegistrationApiUrl +
        'inspection-recommendation-type/save',
      getInspectionRecommendationType:
        environment.stpRegistrationApiUrl +
        'inspection-recommendation-type/get-inspection-recommendation-type/',
      getAllInspectionRecommendationByType:
        environment.stpRegistrationApiUrl +
        'inspection-recommendation-type/get-all-inspection-recommendation-by-type',
      getAllInspectionRecommendationTypes:
        environment.stpRegistrationApiUrl +
        'inspection-recommendation-type/get-all-inspection-recommendation-types',
    },
    cctv: {
      saveCctv:
        environment.stpRegistrationApiUrl +
        'inspection/save-camera-renewal-application-inspection',
      saveCctvForCourseAccInspectionReport:
        environment.stpRegistrationApiUrl +
        'inspection/save-camera-information-inspection-report',
    },
    scrutiny: {
      getByInspectionId:
        environment.stpRegistrationApiUrl +
        'scrutiny-report/get-inspection-reports-recommendation-details',
      saveByInspectionId:
        environment.stpRegistrationApiUrl + 'scrutiny-report/save',
      rollBackByInspectionId:
        environment.stpRegistrationApiUrl + 'scrutiny-report/rollback',
    },
    decision: {
      getDecisionByInspectionId:
        environment.stpRegistrationApiUrl +
        'decision/get-inspection-decision-details-by-inspection-id',
      save: environment.stpRegistrationApiUrl + 'decision/save',
      submitByInspectionId: environment.stpRegistrationApiUrl + 'decision/submit-course-accreditation-decision',
      rollBackDecisionByInspectionId: environment.stpRegistrationApiUrl + 'decision/rollback',
    },
    report: {
      getAllCourseAccreditationInfo: environment.stpRegistrationApiUrl + 'inspection/get-all-inspections-for-admin-report',
    }
  },

  physicalInfra: {
    readById:
      environment.stpRegistrationApiUrl +
      'registration/stp-infrastructure-details/',
  },

  changePassword: {
    update: environment.baseApiUrl + 'user/changePassword',
  },
  financialDetails: {
    create: environment.stpRegistrationApiUrl + 'registration/financialDetails',
    update:
      environment.stpRegistrationApiUrl +
      'registration/financialDetails/update',
    fundsById: environment.stpRegistrationApiUrl + 'fund/',
    incomeById: environment.stpRegistrationApiUrl + 'income/',
    expenseById: environment.stpRegistrationApiUrl + 'expense/',
  },
  otp: {
    readContact: environment.stpRegistrationApiUrl + 'send-otp-mobile/',
    readOtp: environment.stpRegistrationApiUrl + 'verify-otp-mobile',
    readOtpToEmail: environment.stpRegistrationApiUrl + 'verify-otp-email',
    sendOTP: environment.stpRegistrationApiUrl + 'inspection-report/send-otp',
    sendingOTPtoMail:
    environment.stpRegistrationApiUrl + 'sendOtpToEmail',
    verifyOTP:
      environment.stpRegistrationApiUrl + 'inspection-report/verify-otp',
  },
  stpFullInformation: {
    userFullInformation:
      environment.stpRegistrationApiUrl + 'registration/stp-user/',
  },
  certificate: {
    downloadCertificateByStpMainId:
      environment.stpRegistrationApiUrl + 'stpMain/download-certificate',
    readByStpMainId: environment.stpRegistrationApiUrl + 'stpMain',
    readByStpMainDecreptionId:
      environment.stpRegistrationApiUrl + 'stpMainDecreption/Do',
    sendingMailByStpMainId:
      environment.stpRegistrationApiUrl + 'sendCertificateEmail',
  },
  registrationCard: {
    registrationCardGenerateApi:
      environment.stpRegistrationApiUrl + 'profile/registration-card',
    profileApplicantDetailsById:
      environment.stpRegistrationApiUrl + 'profile/applicant-details-list',
    readByTamMainDecreptionId:
      environment.stpRegistrationApiUrl + 'tamMainDecreption/Do',
  },

  actionHistory: {
    isApplicationSubmitted:
      environment.stpRegistrationApiUrl +
      'action-history/is-application-submitted',
    applicationSubmit:
      environment.stpRegistrationApiUrl + 'action-history/submit-application',
  },
  occupationLevel: {
    registrationOccupationandLevelDetailsById:
      environment.tamApiUrl + 'tam-main',
  },

  cadEquipment: {
    create: environment.tamApiUrl + 'save-tam-equipments-info',
    readByTamId: environment.tamApiUrl + 'tam-equipments-info/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-equipments-info',
    delete: environment.tamApiUrl + 'tam-equipments-info/',
    update: environment.tamApiUrl + 'update-tam-equipments-info/',
  },

  cadFurniture: {
    create: environment.tamApiUrl + 'save-tam-furniture',
    readByTamId: environment.tamApiUrl + 'tam-furniture/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-furniture',
    delete: environment.tamApiUrl + 'tam-furniture/',
    update: environment.tamApiUrl + 'update-tam-furniture/',
  },

  cadMeasuringInstrument: {
    create: environment.tamApiUrl + 'save-tam-measuring-ins',
    readByTamId: environment.tamApiUrl + 'tam-measuring-ins/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-measuring-ins',
    delete: environment.tamApiUrl + 'tam-measuring-ins/',
    update: environment.tamApiUrl + 'update-tam-measuring-ins/',
  },

  cadTool: {
    create: environment.tamApiUrl + 'save-tam-tools',
    readByTamId: environment.tamApiUrl + 'tam-tools/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-tools',
    delete: environment.tamApiUrl + 'tam-tools/',
    update: environment.tamApiUrl + 'update-tam-tools/',
  },

  cadMaterialConsumable: {
    create: environment.tamApiUrl + 'save-material-consumable',
    readByTamId: environment.tamApiUrl + 'tam-material-consumables/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-material-consumable',
    delete: environment.tamApiUrl + 'tam-material-consumables/',
    update: environment.tamApiUrl + 'update-tam-material-consumables/',
  },

  cadPpe: {
    create: environment.tamApiUrl + 'save-tam-pps',
    readByTamId: environment.tamApiUrl + 'tam-ppes/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-pps',
    delete: environment.tamApiUrl + 'tam-ppes/',
    update: environment.tamApiUrl + 'update-tam-ppes/',
  },

  cadLearningMaterial: {
    create: environment.tamApiUrl + 'save-tam-learning-material',
    readByTamId: environment.tamApiUrl + 'tam-learning-materials/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-learning-material',
    delete: environment.tamApiUrl + 'tam-learning-material/',
    update: environment.tamApiUrl + 'update-tam-learning-material/',
  },

  cadTrainingFacility: {
    create: environment.tamApiUrl + 'save-tam-training-facilities',
    readByTamId: environment.tamApiUrl + 'tam-training-facilities/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-training-facility',
    delete: environment.tamApiUrl + 'tam-training-facilities/',
    update: environment.tamApiUrl + 'update-tam-training-facilities/',
  },

  cadTrainerAndStaff: {
    create: environment.tamApiUrl + 'save-tam-trainer-staff',
    readByTamId: environment.tamApiUrl + 'tam-trainer-staffs/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-tam-trainers-staff',
    delete: environment.tamApiUrl + 'tam-trainer-staffs/',
    update: environment.tamApiUrl + 'update-tam-trainer-staff/',
  },

  cadOtherRequirement: {
    create: environment.tamApiUrl + 'save-tam-other-req',
    readByTamId: environment.tamApiUrl + 'tam-other-reqs/tam-main/',
    saveExcelData: environment.tamApiUrl + 'save-all-other-requirement',
    delete: environment.tamApiUrl + 'tam-other-reqs/',
    update: environment.tamApiUrl + 'update-tam-other-req/',
  },

  csGeneric: {
    create: environment.tamApiUrl + 'save-tam-generic-competency',
    readByTamId: environment.tamApiUrl + 'tam-generic-competencies/tam-main/',
    update: environment.tamApiUrl + 'update-tam-generic-competency/',
    delete: environment.tamApiUrl + 'tam-generic-competencies/',
  },

  csSepcificSector: {
    create: environment.tamApiUrl + 'save-tam-specific-competency',
    readByTamId: environment.tamApiUrl + 'tam-specific-competencies/tam-main/',
    update: environment.tamApiUrl + 'update-tam-specific-competency/',
    delete: environment.tamApiUrl + 'tam-specific-competencies/',
  },

  csSpecificOccupation: {
    create: environment.tamApiUrl + 'save-occupation-specific-competency',
    readByTamId:
      environment.tamApiUrl + 'tam-occupation-specific-competencies/tam-main/',
    update: environment.tamApiUrl + 'update-occupation-specific-competency/',
    delete: environment.tamApiUrl + 'tam-occupation-specific-competencies/',
  },

  tamMain: {
    create: environment.tamApiUrl + 'save-tam-main',
    read: environment.tamApiUrl + 'tam-mains',
    readTamMains: environment.tamApiUrl + 'tam-mains-all',
    readById: environment.tamApiUrl + 'tam-main/',
    update: environment.tamApiUrl + 'update-tam-main/',
    delete: environment.tamApiUrl + 'tam-main/',
    search: environment.tamApiUrl + 'tam-mains-search',
    searchWithoutPagination: environment.tamApiUrl + 'tam-mains-search-without-pagination',
    readTamArchiveInfo: environment.tamApiUrl + 'get-tam-main-archive-info',
    getAllTamOccupationsAndLevels:
      environment.tamApiUrl + 'tam-occupations-level',
    getDownloadTamMainReport: environment.tamApiUrl + 'tam-mains-download',
    downloadTamMainArchivedInfo: environment.tamApiUrl + 'tam-mains-download-archived-info',
    readApprovedCSOccupation: environment.tamApiUrl + 'get-approved-cs-occupation',
    readTamMainCsInformation: environment.tamApiUrl + 'get-tam-main-cs-info',
  },

  tamOccupation: {
    create: environment.tamApiUrl + 'save-tam-occupation',
    read: environment.tamApiUrl + 'tam-occupation-list',
    readById: environment.tamApiUrl + 'tam-occupation/',
    update: environment.tamApiUrl + 'tam-occupation/',
    delete: environment.tamApiUrl + 'tam-occupation/',
    tamOccupationSearchList:
      environment.tamApiUrl + 'tam-occupation-search-list',
  },

  tamType: {
    read: environment.tamApiUrl + 'tam-types',
  },

  tamLevel: {
    read: environment.tamApiUrl + 'tam-levels',
  },

  tamLanguage: {
    read: environment.tamApiUrl + 'tam-languages',
  },
  tamIndustrialSector: {
    read: environment.tamApiUrl + 'tam-industrial-sectors',
    checkIndustryNameExist: environment.tamApiUrl + 'check-industry-name-exist',
    checkIndustryShortNameExist: environment.tamApiUrl + 'check-industry-short-name-exist',
    saveTamIndustrialSector: environment.tamApiUrl + 'save-tam-industrial-sector',
    getAllTamIndustrialSectors: environment.tamApiUrl + 'get-all-tam-industrial-sectors',
  },

  file: {
    upload: environment.fileServiceApiUrl + 'uploadfile',
    download: environment.tamApiUrl + 'file-download',
  },
  tci: {
    saveExcelData: environment.tciUrl + 'save-all-tci-info',
    getAllTciList: environment.tciUrl + 'tci-infos',

    getDownloadFreelancerCertificate: environment.tciUrl + 'tci-info/',
    getFreelancerCertificateByEncryptedId:
      environment.tciUrl + 'rpl-certificate/',
    sendAllEmail: environment.tciUrl + "send-all-email",
  },
  certificates:{
    getAllCertificates: environment.commonServiceApiUrl + 'certificate/get-all-certificates'
  },

  freelancerUserVerification: {
    checkEmailExist: environment.baseApiUrl + 'check-email-exist',
    checkNameExist:
      environment.stpRegistrationApiUrl + 'registration/check-stp-name-exist',
  },
  freelancerOtp: {
    readContact: environment.stpRegistrationApiUrl + 'send-otp-mobile/',
    readOtp: environment.stpRegistrationApiUrl + 'verify-otp-mobile',
  },

  freelancerUser: {
    login: environment.baseApiUrl + 'api/auth/signin/',
    register: environment.baseApiUrl + 'selfRegistration',
    createFreelancerUser:
      environment.stpRegistrationApiUrl + 'registration/stp-users',
  },

  assessorUser: {
    //login: environment.baseApiUrl + 'api/auth/signin/',
    register: environment.baseApiUrl + 'selfRegistration',
    //createFreelancerUser: environment.stpRegistrationApiUrl + 'registration/stp-users',
  },

  freelancer: {
    readApplicantShortList:
      environment.stpRegistrationApiUrl + 'profile/applicant-details',
    readApplicantOccupations: environment.tamApiUrl + 'tam-mains-all',
    applicantApprovalUpdate:
      environment.stpRegistrationApiUrl +
      'profile/update-applicant-details-status',
  },

  applicationSkillCertificate: {
    create: environment.stpRegistrationApiUrl + 'profile/save-applicant',
    readByAclUserId:
      environment.stpRegistrationApiUrl + 'profile/applicant-details-user/',
    readByprofileApplicantId:
      environment.stpRegistrationApiUrl + 'profile/applicant-details/',
    readReligion: environment.stpRegistrationApiUrl + 'religions',
    readNationalitys: environment.stpRegistrationApiUrl + 'countries',
    applicationDetailsID:
      environment.stpRegistrationApiUrl + 'profile/applicant-detail/',
  },
  courseAccInspectionReport: {
    saveCourseAccInspectionReport:
      environment.stpRegistrationApiUrl +
      'inspection/save-course-accreditation-inspection-report',
    savecourseAccInspectionCadReport:
      environment.stpRegistrationApiUrl +
      'inspection/save-course-accreditation-inspection-report-cad',
    saveStpSacRegistrationInspectionReport:
      environment.stpRegistrationApiUrl +
      'inspection-registration-report/save-inspection-registration-report',
    deleteCourseAccInspectionReportAttachment:  environment.stpRegistrationApiUrl + 'inspection/delete-course-accreditation-inspection-report-attachment',
    deleteStpRegInspectionReportAttachment:   environment.stpRegistrationApiUrl + 'inspection-registration-report/delete-stp-registration-inspection-report-attachment'
  },
  recommendation: {
    getAllRecommendation:
      environment.stpRegistrationApiUrl +
      'inspection-recommendation-type/get-all-inspection-recommendation-types',
    getRecommendationByType:
      environment.stpRegistrationApiUrl +
      'inspection-recommendation-type/get-all-inspection-recommendation-by-type',
  },
  scrutiny: {
    saveScrutinyForStpSacInspecctionReport:
      environment.stpRegistrationApiUrl +
      'scrutiny-report/update-scrutiny-result-stpSac',
    saveCourseAccInspectionReportPreview:
      environment.stpRegistrationApiUrl +
      'scrutiny-report/update-scrutiny-result-accreditation-inspectionReport',
  },
  graduateTracking: {
    create: environment.stpOperationApiUrl + 'graduate-tracking',
    getByTraineeId: environment.stpOperationApiUrl + '/graduate-tracking/trainee-id',
    getTraineeEmploymentAndIncomeInfoById: environment.stpOperationApiUrl + 'graduate-tracking/graduate-tracking-by-trainee-id/',
  },
  employmentStatus: {
    getAllEmploymentStatus: environment.stpOperationApiUrl + 'employment-status',
  },
  projectInitiative: {
    create: environment.stpOperationApiUrl + 'project-initiative/save-project-initiatives',
    checkProjectShortNameExist: environment.stpOperationApiUrl + 'project-initiative/check-project-short-name',
    getAllProjectsByStpMainId: environment.stpOperationApiUrl + 'project-initiative/get-all-projects-by-stpMainId',
    getAllProjectInitiatives: environment.stpOperationApiUrl + 'project-initiative/get-all-project-initiatives',
    getExcelProjectInitiatives: environment.stpOperationApiUrl + 'project-initiative/get-excel-Download',
    getProjectInitiativeById: environment.stpOperationApiUrl + 'project-initiative/get-project-initiative',
    updateProjectInitiative: environment.stpOperationApiUrl + 'project-initiative/update-project-initiatives/',
    getProjectInfoToCreateProjectByProjectId: environment.stpOperationApiUrl + "project-initiative/course-create-by-project/",
    deleteProject: environment.stpOperationApiUrl + 'project-initiative/delete-project/',
    getProjectInitiativeByStpMainId: environment.stpOperationApiUrl + 'project-initiative/get-project-initiative-by-stp-main-id',

  },
  rollbackurls: {

    getApplStatusById: environment.stpRegistrationApiUrl + 'rollback/get-appl-status',
    rollBStat: environment.stpRegistrationApiUrl + 'rollback/update-status',

  },
  batch: {
    create: environment.stpOperationApiUrl + 'batch/save-batch',
    getAllBatch: environment.stpOperationApiUrl + 'batch/get-All-batch',
    deleteBatch: environment.stpOperationApiUrl + 'batch/delete-batch/',
    readBatchById: environment.stpOperationApiUrl + 'batch/get-batch-by-id/',
    updateBatch: environment.stpOperationApiUrl + 'batch/update-batch/',
    getIndividualBatchById: environment.stpOperationApiUrl + 'batch/get-individualBatch/',
    batchListForDropDown: environment.stpOperationApiUrl + 'batch/get-batch-list',
    getBatchByCourseId: environment.stpOperationApiUrl + 'batch/batch-create-by-course/',
    downloadBatchReport: environment.stpOperationApiUrl + 'batch/get-batch-excel-reportInfo',
    getBatchByStpMainId: environment.stpOperationApiUrl + 'batch/get-batch-by-stp-main-id',
    getAllBatchesByStp: environment.stpOperationApiUrl + 'batch/get-all-batches-by-stp'
  },
  course: {
    saveCourseInfo: environment.stpOperationApiUrl + "course/save-course",
    getPageableCourseList: environment.stpOperationApiUrl + "course/get-pageable-courses",
    getcoursesStp: environment.stpOperationApiUrl + "course/get-all-courses-stp",
    getCourseById: environment.stpOperationApiUrl + "course/get-course-by-id",
    getAllCourse: environment.stpOperationApiUrl + "course/get-all-courses-stp",
    getBatchByCourseId: environment.stpOperationApiUrl + "course/batch/",
    getCourseByProjectId: environment.stpOperationApiUrl + "course/project/",
    getCourseInfoExcel: environment.stpOperationApiUrl + 'course/get-course-info-excel',
    deleteCourseById: environment.stpOperationApiUrl + 'course/delete-course-by-id',
    getCourseByStpMainId: environment.stpOperationApiUrl + 'course/get-course-by-stp-main-id',
    getStpCenterByTamMainId: environment.stpOperationApiUrl + 'course/check-stp-center-if-exist',
    getStpCenterForRplAssessmentByTamMainId: environment.stpOperationApiUrl + 'course/check-stp-center-if-exist-for-rpl-assessment-apply'
  },
  trainer: {
    readGender: environment.stpOperationApiUrl + 'trainer/gender',
    readTrainerCurrentStatus:
      environment.stpOperationApiUrl + 'trainer/trainer-current-status',
    readReligion: environment.stpOperationApiUrl + 'trainer/religion',
    readEthnicity: environment.stpOperationApiUrl + 'trainer/ethnicity',
    readEducationalQualification:
      environment.stpOperationApiUrl + 'trainer/educationalQualification',
    update: environment.stpOperationApiUrl + 'trainer/update-trainer/',
    create: environment.stpOperationApiUrl + 'trainer/save-trainer',
    getAllTrainerData:
      environment.stpOperationApiUrl + 'trainer/get-all-trainer',
    readTrainerById: environment.stpOperationApiUrl + 'trainer/trainer/',
    readTrainingBatchesInfoByTrainerId: environment.stpOperationApiUrl + 'trainer/training-batches/',
    readTechnicalSkillByTrainerId: environment.stpOperationApiUrl + 'trainer/technical-skill/',
    downloadTrainerReport: environment.stpOperationApiUrl + 'trainer/trainer-excel-download',
    getAllTrainersByOccupationIdAndLevelId:
      environment.stpOperationApiUrl + 'trainer/get-all-trainers-by-occupationId-and-levelId',
    deleteTrainerById: environment.stpOperationApiUrl + 'trainer/delete-trainer-by-id/',
    getTrainerByStpMainId: environment.stpOperationApiUrl + 'trainer/get-trainer-by-stp-main-id',
  },

  trainee: {
    create: environment.stpOperationApiUrl + 'trainee/save-trainee',
    getAllTrainees: environment.stpOperationApiUrl + 'trainee/get-all-trainees',
    getTraineeEnrollInfoByBatchId: environment.stpOperationApiUrl + 'trainee/get-trainee-enroll-info',
    getAllTraineesWithoutBatchId: environment.stpOperationApiUrl + 'trainee/get-all-trainees-without-batchId',
    removeTraineeById: environment.stpOperationApiUrl + 'trainee/remove-trainee-by-id',
    dropoutTrainee: environment.stpOperationApiUrl + 'trainee/dropout-trainee',
    readTraineeById: environment.stpOperationApiUrl + 'trainee/trainee/',
    update: environment.stpOperationApiUrl + 'trainee/update-trainee/',
    updateTraineeAdmin: environment.stpOperationApiUrl + 'trainee/update-trainee-admin/',
    readTraineePersonalDataById: environment.stpOperationApiUrl + 'trainee/trainee-view/',
    readTrainingAndAssessmentDataByTraineeId: environment.stpOperationApiUrl + 'trainee/get-training-and-assessment/',
    downloadTraineeReport: environment.stpOperationApiUrl + 'trainee/trainee-excel-download',
    saveExcelData: environment.stpOperationApiUrl + 'trainee/save-trainee-list',
    deleteTraineeById: environment.stpOperationApiUrl + 'trainee/delete-trainee-by-id',
    hardDeleteTraineeById: environment.stpOperationApiUrl + 'trainee/hard-delete-trainee-by-id/',
    getTraineeByStpMainId: environment.stpOperationApiUrl + 'trainee/get-trainee-by-stp-main-id',
  },
  rpl: {
    readReviewApplicationById: environment.stpOperationApiUrl + 'assessment/get-rpl-assessment-by-applicationId/',
    getAllCandidate: environment.stpOperationApiUrl + 'RPL/candidate/get-all-candidates',
    getAssessmentApplicationList: environment.stpOperationApiUrl + 'assessment/get-all-assessment-application/',
    acceptReviewApplication: environment.stpOperationApiUrl + 'assessment/accept-rpl-assessment/',
    needCorrection: environment.stpOperationApiUrl + "assessment/correction-request-rpl-assessment",
    getNullableAssessApplyIdCandidateList: environment.stpOperationApiUrl + 'RPL/nullable-assessment-apply-id-candidate-list',
    update: environment.stpOperationApiUrl + 'assessment/update-rpl-assessment/',
    create: environment.stpOperationApiUrl + 'assessment/save-rpl-assessment',
    submitRplBatchAssessment: environment.stpOperationApiUrl + "assessment/submit-rpl-assessment",
    getRPLAssessmentInfo: environment.commonServiceApiUrl + 'common/get-rpl-assessment-admin-list',
    getRPLAssessmentInfoByStatus: environment.stpOperationApiUrl + 'assessment/get-rpl-assessment-admin-list-by-status',
    readRplAssessmentAppListByCertificatesPendingStatus: environment.commonServiceApiUrl + 'common/get-rpl-assessment-app-list-by-certificates-pending-status',
    readRplAssessmentAppListByCertificatesIssuedStatus: environment.commonServiceApiUrl + 'common/get-rpl-assessment-app-list-by-certificates-issued-status',
    readRegularAssessmentAppListByCertificatesPendingStatus: environment.commonServiceApiUrl + 'common/get-regular-assessment-app-list-by-certificates-pending-status',
    readRegularAssessmentAppListByCertificatesIssuedStatus: environment.commonServiceApiUrl + 'common/get-regular-assessment-app-list-by-certificates-issued-status',
    getAssessmentType: environment.stpOperationApiUrl + "assessment/get-assessment-type",
    readAssessors: environment.stpOperationApiUrl + 'assessor/get-all-assessors',
    readAssessorsByOccupationAndLevel: environment.stpOperationApiUrl + 'assessor/get-assessors-by-occupation-and-level',
    saveRplAssessmentSchedule:
      environment.stpOperationApiUrl +
      'assessment/save-rpl-assessment-schedule',
    submitRplAssessmentSchedule: environment.stpOperationApiUrl + "assessment/submit-rpl-assessment-schedule",
    readRplRegAssessHistoryByAssessApplyId: environment.stpOperationApiUrl + 'assessment/rpl-reg-assess-history-by-assess-apply-id/',
    submitVerificationResultIfFailed: environment.stpOperationApiUrl + "assessment/submit-rpl-payment-verification-result",
    removeRplCandidateFromAssessment: environment.stpOperationApiUrl + 'assessment/remove-candidate-from-assessment',
    submitAssessmentResult: environment.stpOperationApiUrl + "regular-rpl-result/submit-regular-rpl-result",
    readUploadResultsInfoById: environment.stpOperationApiUrl + 'regular-rpl-result/get-upload-result-data/',
    readAssessorsUploadResultInfoById: environment.stpOperationApiUrl + 'regular-rpl-result/get-assessors-upload-result-data',
    saveResults: environment.stpOperationApiUrl + 'regular-rpl-result/save-regular-rpl-result',
    updateUploadedResults: environment.stpOperationApiUrl + 'regular-rpl-result/save-regular-rpl-result',
    assessorNeedCorrection: environment.stpOperationApiUrl + 'regular-rpl-result/assessor-need-correction',
    approveResults: environment.stpOperationApiUrl + "regular-rpl-result/approve-result",
    needClarification: environment.stpOperationApiUrl + 'regular-rpl-result/clarification-or-correction',
    issueCertificates: environment.stpOperationApiUrl + "regular-rpl-result/issue-certificates",
    readAssessorsByOccupationAndLevelForRplResults: environment.stpOperationApiUrl + 'assessor/get-assessors-by-occupation-and-level-for-reg-rpl',
    readAssessorInfoById: environment.stpOperationApiUrl + 'assessor/get-rpl-assessors',
    collectDataToDownloadCertificate: environment.stpOperationApiUrl + 'assessment/collect-candidate-To-certificate-download/',
    downloadRplCertificateFromTci: environment.tciUrl + 'download-rpl-certificate',
    downloadRplBatchAssessmentReport: environment.stpOperationApiUrl + 'assessment/rpl-batch-assessment-excel-download',
    getCertificateDataForRplCandidate: environment.stpOperationApiUrl + 'assessment/rpl-candidate-info-certificate',
    getCertificateDataForRegularCandidate: environment.stpOperationApiUrl + 'assessment/reg-candidate-info-certificate',

  },
  rplPayment: {
    paymentForRPL:
      environment.stpOperationApiUrl + 'assessment-payment/save-rpl-offline-payment',
  },
  common: {
    getAllTrainingType: environment.stpOperationApiUrl + 'common/get-All-training_type',
    getIdType: environment.stpOperationApiUrl + 'common/id-type-list',
    getGender: environment.stpOperationApiUrl + 'common/gender-list',
    getReligion: environment.stpOperationApiUrl + 'common/religion-list',
    getEthnicity: environment.stpOperationApiUrl + 'common/ethnicity-list',
    getEducationalQualification: environment.stpOperationApiUrl + 'common/educational-qualification-list',
    getRuralOrUrban: environment.stpOperationApiUrl + 'common/rural-or-urban-list',
    getMaritalStatus: environment.stpOperationApiUrl + 'common/martial-status-list',
    getEmploymentStatus: environment.stpOperationApiUrl + 'common/employment-status-list',
    getLearnAboutTraining: environment.stpOperationApiUrl + 'common/learn-about-training-list',
    readDataCollectionCycle: environment.stpOperationApiUrl + 'common/data-collection-cycle/',
    readTrainingType: environment.stpOperationApiUrl + 'common/get-training-types',
  },
  assessment: {
    saveAssessment: environment.stpOperationApiUrl + 'assessment/save-assessment',
    getAllTraineesByCurrentBatchId: environment.stpOperationApiUrl + 'trainee/get-all-trainees-by-current-batchId',
    getAllTraineesFromPreviousBatchId: environment.stpOperationApiUrl + 'trainee/get-all-trainees-from-previous-batchId',
    getAssessmentsByBatchId: environment.stpOperationApiUrl + "assessment/get-assessments-by-batch-id",
    getRegAssessmentInfo: environment.commonServiceApiUrl + 'common/get-regular-assessment-admin-list',
    downloadRegularBatchAssessmentReport: environment.stpOperationApiUrl + 'assessment/regular-batch-assessment-excel-download',
    getAssessmentResult: environment.stpOperationApiUrl + 'assessment/get-assessment-result',
    getAssessmentResultAdmin: environment.stpOperationApiUrl + 'assessment/get-assessment-result-admin',
    getAssessmentResultReport: environment.stpOperationApiUrl + 'assessment/get-assessment-result-report',
    getAssessmentResultReportAdmin: environment.stpOperationApiUrl + 'assessment/get-assessment-result-report-admin',
    downloadAssessmentApplicationReportStp: environment.stpOperationApiUrl + 'assessment/assessment-excel-download-stp',
    onRollBack: environment.stpOperationApiUrl + 'assessment/assessment-roll-back'
  },
  executeTraining: {
    saveExecuteTraining: environment.stpOperationApiUrl + 'execute-training/save-execute-training',
    startTrainingById: environment.stpOperationApiUrl + 'execute-training/start-training-by-id',
    completeTrainingById: environment.stpOperationApiUrl + 'execute-training/complete-training-by-id',
    getBatchAndTraineeInfoByBatchId:
      environment.stpOperationApiUrl + 'execute-training/get-batch-and-trainee-info-by-batchId/',
    getExecuteTrainingByBatchId: environment.stpOperationApiUrl + 'execute-training/get-execute-training-by-batchId'
  },
  RPLBatch: {
    createRplBatch: environment.stpOperationApiUrl + 'RPL/save-rpl-batch',
    getRplCandidateByRegistrationNumber: environment.stpOperationApiUrl + 'RPL/get-rpl-candidate-by-registration-no/',
    getAllCandidate: environment.stpOperationApiUrl + 'RPL/get-all-rpl-candidate',
    updateCandidateForReAssessment: environment.stpOperationApiUrl + 'RPL/re-assessment/',
    updateCandidateForReAssessmentAdmin: environment.stpOperationApiUrl + 'RPL/re-assessment-admin/',
    getCandidateInfoByCandidateId: environment.stpOperationApiUrl + 'RPL/get-candidate-by-id/',
    getCandidateInfoByStpMainId: environment.stpOperationApiUrl + 'RPL/get-candidate-by-stp-main-id',
    deleteCandidate: environment.stpOperationApiUrl + 'RPL/delete-candidate/',
    rplAssessmentCanidateDownloadCertificate: environment.stpOperationApiUrl + 'RPL/rpl-candidate-certificate-download/'
  },
  RPLIndividual: {
    searchCandidateByOccupationAndLevel: environment.stpRegistrationApiUrl + 'profile/get-candidates',
    setCandidateAssessmentApplicationIdToNull: environment.stpRegistrationApiUrl + 'rpl-individual/set-profile-applicant-assessment-id-to-null',
    saveSelectedCandidates: environment.stpRegistrationApiUrl + 'rpl-individual/save',
    updateSelectedCandidates: environment.stpRegistrationApiUrl + 'rpl-individual/update',
    getPageableApplicationList: environment.stpRegistrationApiUrl + "rpl-individual/get-pageable-rpl-individual-assessment-applications",
    submitIndividualAssessment: environment.stpRegistrationApiUrl + 'rpl-individual/submit',
    getAllCandidateByAssessmentApplyId: environment.stpRegistrationApiUrl + 'rpl-individual/get-all-candidate-by-id',
    deleteAssessmentApplicationById: environment.stpRegistrationApiUrl + 'rpl-individual/delete/'
  },
  recaptcha: {
    verifyCaptcha: environment.tciUrl + 'verify-captcha'
  },

  regBatchAssessment: {
    saveRegBatchAssessment: environment.stpOperationApiUrl + "assessment/save-regular-assessment",
    getRegAssessmentByBatchId: environment.stpOperationApiUrl + "assessment/get-regular-assessment-by-batchId",
    getRegAssessmentByApplicationId: environment.stpOperationApiUrl + "assessment/get-regular-assessment-by-applicationId/",
    updateRegBatchAssessment: environment.stpOperationApiUrl + "assessment/update-regular-assessment",
    readRegReviewApplicationById: environment.stpOperationApiUrl + 'assessment/get-regular-assessment-by-applicationId/',
    acceptRegReviewApplication:
      environment.stpOperationApiUrl +
      'assessment/accept-regular-assessment/',
    needCorrection: environment.stpOperationApiUrl + "assessment/correction-request-regular-assessment",
    saveRegAssessmentSchedule:
      environment.stpOperationApiUrl +
      'assessment/save-regular-assessment-schedule',
    submitRegAssessmentSchedule: environment.stpOperationApiUrl + "assessment/submit-regular-assessment-schedule",
    offlinePayment: environment.stpOperationApiUrl + "assessment-payment/save-reg-offline-payment",
    submitRegAssessment: environment.stpOperationApiUrl + "assessment/submit-reg-assessment",
    getPaymentInfo: environment.stpOperationApiUrl + "assessment/get-payment-info/",
    initiatePayment: environment.paymentUrl + "payment/initiate-assessment-payment",
    getRegAssessmentApplicationsByAssessmentId: environment.stpOperationApiUrl + 'assessment/get-regular-assessment-candidate-list-by-assessmentId/',
    submitPaymentVerificationResult: environment.stpOperationApiUrl + "assessment/submit-reg-payment-verification-result",
    getOtherApprovedAssessmentCenter: environment.stpRegistrationApiUrl + "inspection/get-other-approved-assessment-center",

    readRplRegAssessHistoryByApplicationId: environment.stpOperationApiUrl + 'assessment/rpl-reg-assess-history-by-assess-apply-id/',
    removeTraineeFromAssessment: environment.stpOperationApiUrl + 'assessment/remove-trainee-from-assessment',
    updatePaymentSlip: environment.stpOperationApiUrl + 'assessment-payment/update-payment-slip',
    readRegAssessorInfoById: environment.stpOperationApiUrl + 'assessor/get-regular-assessors',
    readRegRplResultStatusIInfoById: environment.stpOperationApiUrl + 'assessor/get-regular-rpl-result-info',
    collectDataToDownloadCertificate: environment.stpOperationApiUrl + 'assessment/reg-collect-candidate-To-certificate-download/'
  },
  changeNAPPassword: {
    change: environment.napApiUrl + 'change-password',
  },
  agencyChangePassword: {
    change: environment.napApiUrl + 'admin/change-password-by-admin',
  },
  agencyUser: {
    login: environment.napApiUrl + 'login',
    checkEmailExist: environment.napApiUrl + 'check-email-exist',
    forgotpassword: environment.napApiUrl + 'forgot-password',
    resetpassword: environment.napApiUrl + 'reset-password',
    saveAgencyUser: environment.napApiUrl + 'admin/create-user',
    getMinistryListByStpOwnershipTypeId:
      environment.stpRegistrationApiUrl + 'ministry-list/stp-ownership-type',
    getAgencyByMinistryId: environment.stpRegistrationApiUrl + 'agency/ministry',
    getAgencyUserById: environment.napApiUrl + 'admin/get-agency-user-by-id',
    getAllAgencyUsers: environment.napApiUrl + 'admin/get-all-agency-users'
  },
  organizationProfile: {
    getAllDivisions: environment.napApiUrl + 'get-divisions',
    getDistrictsByDivisionId: environment.napApiUrl + 'get-districts-by-division',
    getUpazillasByDistrictId: environment.napApiUrl + 'get-upazillas-by-district',
    getCustomAgency: environment.napApiUrl + 'get-custom-agency',
    updateAgencyProfile: environment.napApiUrl + 'update-agency-profile'
  },
  napTarget: {
    getAllFiscalYears: environment.napApiUrl + 'get-fiscal-years',
    getNapTargets: environment.napApiUrl + 'get-nap-targets',
    saveNapTargets: environment.napApiUrl + 'save-nap-targets',
    submitNapTargets: environment.napApiUrl + 'submit-nap-targets'
  },
  trainingTarget: {
    getTrainingTargetYears: environment.napApiUrl + 'get-training-target-years',
    getTrainingSectors: environment.napApiUrl + 'get-training-sectors',
    getTrainingTargets: environment.napApiUrl + 'get-training-targets',
    deleteTrainingTarget: environment.napApiUrl + 'delete-training-target',
    saveTrainingTargets: environment.napApiUrl + 'save-training-targets',
    submitTrainingTargets: environment.napApiUrl + 'submit-training-targets'
  },
  submitNAPReport: {
    getUtilsData: environment.napApiUrl + 'get-util-data',
    getNapReports: environment.napApiUrl + 'get-nap-reports',
    saveNapReports: environment.napApiUrl + 'save-nap-reports',
    submitNapReports: environment.napApiUrl + 'submit-nap-reports'
  },
  submitTrainingReport: {
    getUtilsData: environment.napApiUrl + 'get-util-data',
    getTrainingReports: environment.napApiUrl + 'get-training-reports',
    saveTrainingReports: environment.napApiUrl + 'save-training-reports',
    submitTrainingReports: environment.napApiUrl + 'submit-training-reports',
  },
  reviewNAPTarget: {
    getNapTargets: environment.napApiUrl + 'admin/get-nap-targets',
    getFiscalYearsByAdmin: environment.napApiUrl + 'admin/get-fiscal-years',
    approveNapTarget: environment.napApiUrl + 'admin/approve-nap-targets',
    NAPTargetsRemarksSubmit: environment.napApiUrl + 'admin/revise-nap-targets'
  },
  reviewTrainingTarget: {
    getTrainingTargetYears: environment.napApiUrl + 'admin/get-training-target-years',
    getTrainingSectors: environment.napApiUrl + 'admin/get-training-sectors',
    getTrainingTargets: environment.napApiUrl + 'admin/get-training-targets',
    getFiscalYearsByAdmin: environment.napApiUrl + 'admin/get-fiscal-years',
    approveTrainingTarget: environment.napApiUrl + 'admin/approve-training-targets',
    trainingTargetsRemarksSubmit: environment.napApiUrl + 'admin/revise-training-targets'
  },
  reviewNAPReport: {
    getNapReports: environment.napApiUrl + 'admin/get-nap-reports',
    getFiscalYearsByAdmin: environment.napApiUrl + 'admin/get-fiscal-years',
    approveNapReport: environment.napApiUrl + 'admin/approve-nap-reports',
    getUtilsData: environment.napApiUrl + 'admin/get-util-data',
    napReportRemarksSubmit: environment.napApiUrl + 'admin/revise-nap-reports'
  },
  reviewTrainingReport: {
    getTrainingReports: environment.napApiUrl + 'admin/get-training-reports',
    getFiscalYearsByAdmin: environment.napApiUrl + 'admin/get-fiscal-years',
    approveTrainingReport: environment.napApiUrl + 'admin/approve-training-reports',
    getUtilsData: environment.napApiUrl + 'admin/get-util-data',
    trainingReportRemarksSubmit: environment.napApiUrl + 'admin/revise-training-reports'
  },
  ministry: {
    saveMinistry: environment.stpRegistrationApiUrl + 'save-ministry',
    getMinistryList: environment.stpRegistrationApiUrl + 'get-ministry-list',
    getMinistryById: environment.stpRegistrationApiUrl + 'ministry',
    deleteMinistry: environment.stpRegistrationApiUrl + 'delete-ministry'
  },
  agency: {
    saveAgency: environment.stpRegistrationApiUrl + 'save-agency',
    getAgencyList: environment.stpRegistrationApiUrl + 'get-agency-list',
    getAgencyById: environment.stpRegistrationApiUrl + 'agency',
    deleteAgency: environment.stpRegistrationApiUrl + 'delete-agency'
  },
  napFileService: {
    saveTempFile: environment.napApiUrl + 'save-temp-file',
    deleteTempFile: environment.napApiUrl + 'delete-temp-file'
  },
  napAgencyDashboardService:{
    getAgencyDashboard: environment.napApiUrl + 'agency/dashboard/summary/get-dashboard'
  },
  napAdminDashboardService:{
    getAdminDashboard: environment.napApiUrl + 'admin/get-admin-dashboard'
  },
  napTargetAdjustment: {
    getNapTargetsAdjustment: environment.napApiUrl + 'get-nap-targets-adjustment',
    saveNapTargetsAdjustment: environment.napApiUrl + 'save-nap-targets-adjustments',
    submitNapTargetsAdjustment: environment.napApiUrl + 'submit-nap-targets-adjustments'
  },
  trainingTargetAdjustment: {
    getTrainingTargetsAdjustment: environment.napApiUrl + 'get-training-targets-adjustment',
    saveTrainingTargetsAdjustment: environment.napApiUrl + 'save-training-targets-adjustments',
    submitTrainingTargetsAdjustment: environment.napApiUrl + 'submit-training-targets-adjustments'
  },
  reviewNapTargetAdjustment: {
    getNapTargetsAdjustment: environment.napApiUrl + 'admin/get-nap-targets-adjustments',
    approveNapTargetsAdjustment: environment.napApiUrl + 'admin/approve-nap-targets-adjustments',
    reviseNapTargetsAdjustment: environment.napApiUrl + 'admin/revise-nap-targets-adjustments'
  },
  reviewTrainingTargetAdjustment: {
    getTrainingTargetsAdjustment: environment.napApiUrl + 'admin/get-training-targets-adjustment',
    approveTrainingTargetsAdjustment: environment.napApiUrl + 'admin/approve-training-targets-adjustments',
    reviseTrainingTargetsAdjustment: environment.napApiUrl + 'admin/revise-training-targets-adjustments',
    getTrainingTargetYears: environment.napApiUrl + 'admin/get-training-target-years',
    getTrainingSectors: environment.napApiUrl + 'admin/get-training-sectors',
    getTrainingTargets: environment.napApiUrl + 'admin/get-training-targets',
    getFiscalYearsByAdmin: environment.napApiUrl + 'admin/get-fiscal-years'
  },
  assessor: {
    saveAssessorPool: environment.stpOperationApiUrl + 'assessor/save-assessor-pool',
    getAssignedAssessmentInfo: environment.stpOperationApiUrl + 'assessor/get-assigned-assessment-list',
    getAllAssessorsByAclUserId: environment.stpOperationApiUrl + 'assessor/get-all-assessors-by-acl-user-id',
    getAllAssessorsList: environment.stpOperationApiUrl + 'assessor/get-all-assessors-list',
    getTamOccupationForAssessorPoolEntry: environment.tamApiUrl + 'tam-occupation-for-assessor-pool',
    getAssessorByCertificateSerialNumber: environment.commonServiceApiUrl + 'certificate/get-assessor-certificate-serial-number/',
    getAssessorPoolInfoByAssessorId: environment.stpOperationApiUrl + 'assessor/get-assessor-pool-info-by-assessor-id',
    updateAssessorInfo: environment.stpOperationApiUrl + 'assessor/update-assessor/',
    reviewApplicationCorrectionRequest: environment.stpOperationApiUrl + 'assessor/review-application-correction-request',
    saveReviewApplication: environment.stpOperationApiUrl + 'assessor/save-review-application',
    submitReviewApplication: environment.stpOperationApiUrl + 'assessor/submit-review-application',
    deleteAssessorIndustry: environment.stpOperationApiUrl + 'industry/delete-assessor-industry/',
    deleteAssessorSkill: environment.stpOperationApiUrl + 'skill/delete-assessor-skill/',
    deleteAssessorExperience: environment.stpOperationApiUrl + 'experience/delete-assessor-experience/',
    applyAssessorPool: environment.stpOperationApiUrl + 'assessor/submit-assessor-application/',
    readAssessorStatus: environment.stpOperationApiUrl + 'assessor/get-assessor-status-type-dd',
    readGroups: environment.stpOperationApiUrl + 'assessor-common-utils/get-all-recommendation-category',
    readAssessorPoolNsdaData: environment.stpOperationApiUrl + 'assessor/get-assessor-pool-list-nsda',
    onDownloadAssessorPoolNsdaReport: environment.stpOperationApiUrl + 'assessor/get-assessor-pool-nsda-report',
    readReviewResult: environment.stpOperationApiUrl + 'assessor/get-review-result-info-by-id/',
    readDecisionRemarksHistory: environment.stpOperationApiUrl + 'assessor/get-decision-remarks-history-by-id/',
    readAssessorManageRemarksHistory: environment.stpOperationApiUrl + 'assessor/get-assessor-manage-remarks-history-by-assessor-id',
    readDecisionInfo: environment.stpOperationApiUrl + 'assessor/get-decision-info-by-assessor-id/',
    onRollBack: environment.stpOperationApiUrl + 'assessor/decision-roll-back',
    saveDecision: environment.stpOperationApiUrl + 'assessor/save-decision',
    submitDecision: environment.stpOperationApiUrl + "assessor/submit-decision",
    readRplBatchAssessmentRecords: environment.stpOperationApiUrl + 'assessor/get-rpl-batch-assessment-records-by-id/',
    readRegularBatchAssessmentRecords: environment.stpOperationApiUrl + 'assessor/get-regular-batch-assessment-records-by-id/',
    readPastIssues: environment.stpOperationApiUrl + 'assessor/get-past-issues-by-id/',
    saveRecordIssueData: environment.stpOperationApiUrl + 'assessor/save-record-issue',
    readRecordIssues: environment.stpOperationApiUrl + 'assessor/get-record-issues-by-id/',
    onSubmitRecord: environment.stpOperationApiUrl + "assessor/submit-record",
    onDownloadAssessorApplicationListNsdaReport: environment.stpOperationApiUrl + 'assessor/get-assessor-application-list-nsda-info-report',
    withholdAssessorship: environment.stpOperationApiUrl + 'assessor/withhold-assessorship',
    removeWithholdAssessorship: environment.stpOperationApiUrl + 'assessor/remove-withhold-assessorship',
    getAssessorDetailsBhyRegistrationNumber: environment.stpOperationApiUrl + 'assessor/get-assessor-details-registrationNo/',
    saveAssessorAdmin: environment.stpOperationApiUrl + 'assessor/save-assessor-admin',
    renewAssessorPool: environment.stpOperationApiUrl + 'assessor/renew-assessor-pool/',
    updateRenewAssessorPool: environment.stpOperationApiUrl + 'assessor/update-renew-assessor/',
    deleteAssessorById: environment.stpOperationApiUrl + 'assessor/delete-assessor-by-id/',
    checkAssessorIfExist:
      environment.stpOperationApiUrl + 'assessor/get-assessor-by-aclUserId-occupation-and-level',
    getAssessorRecordInfoByAssessorId: environment.stpOperationApiUrl + 'assessor/get-assessor-record-info-by-assessor-id',

  },
  assessorCommonUtils: {
    getAllAssessorStatusTypes: environment.stpOperationApiUrl + 'assessor-common-utils/get-all-assessor-status-types',
    getAllRecommendationCategory: environment.stpOperationApiUrl + 'assessor-common-utils/get-all-recommendation-category'
  },
  pgDocument: {
    getDocumentInfoForNsda: environment.pgApiUrl + 'documents/get-document-list-nsda',
    getDocumentInfoPublic: environment.pgApiUrl + 'documents/get-document-list',
    getDocumentInfoForNsdaArchived: environment.pgApiUrl + 'documents/get-document-list-nsda-archived',
    getDocumentInfoPublicArchived: environment.pgApiUrl + 'documents/get-document-list-archived',
    deleteDocumentByDocumentId: environment.pgApiUrl + 'documents/delete-document-by-document-id/',
    downloadDocumentReport: environment.pgApiUrl + 'documents/document-excel-download',
    downloadOpinionReport: environment.pgApiUrl + 'documents/opinion-excel-download',
    readDocumentType: environment.pgApiUrl + 'documents/get-document-type-dd',
    readVersion: environment.pgApiUrl + 'documents/get-version-dd',
    readLanguage: environment.pgApiUrl + 'documents/get-language-dd',
    readAccessType: environment.pgApiUrl + 'documents/get-access-type-dd',
    saveDocuments: environment.pgApiUrl + 'documents/save-document',
    readDocumentInfoById: environment.pgApiUrl + 'documents/get-document-info-by-id/',
    updateDocuments: environment.pgApiUrl + 'documents/update-document',
    submitDocument: environment.pgApiUrl + "documents/submit-document",
    openToCollectPublicOpinion: environment.pgApiUrl + 'documents/open-for-public-opinion',
    onCloseToCollectingOpinion: environment.pgApiUrl + 'documents/close-for-public-opinion',
    onSendToArchived: environment.pgApiUrl + 'documents/send-to-archieve',
    onSendToLiveList: environment.pgApiUrl + 'documents/send-to-live-list',
    readOpinionInfoById: environment.pgApiUrl + 'document-opinion/get-opinion-info-by-id/',
    saveOpinion: environment.pgApiUrl + 'document-opinion/save-opinion',
    updateOpinion: environment.pgApiUrl + 'document-opinion/update-opinion',
    getDocumentOpinionListByDocumentId: environment.pgApiUrl + 'document-opinion/get-opinion-list-by-document-id/',
    getAllCountry: environment.pgApiUrl + 'country/get-all-countries',
    getCountry: environment.pgApiUrl + 'country/get-country',
    addCountry: environment.pgApiUrl + 'country/add-country',
    deleteCountry: environment.pgApiUrl + 'country/delete-country',
    editCountry: environment.pgApiUrl + 'country/edit-country',
    addMra: environment.pgApiUrl + 'mra/add-mra',
    getAllMra: environment.pgApiUrl + 'mra/get-all-mra',
    deleteMra: environment.pgApiUrl + 'mra/delete-mra',
    searchMra: environment.pgApiUrl + 'mra/search-mra',
    getMraReport: environment.pgApiUrl + 'mra/get-mra-report',
    editMra: environment.pgApiUrl + 'mra/edit-mra',
    getMraById: environment.pgApiUrl + 'mra/get-mra-by-id',
    getAttachmentByMraId: environment.pgApiUrl + 'mra/get-attachment-by-mra-id/',
    getPublicAttachmentByMraId: environment.pgApiUrl + 'mra/get-public-attachment-by-mra-id/',
    getAttachmentFileByMraId: environment.pgApiUrl + 'mra/get-attachment-file-by-mra-id',
    getAgreementTypeByMraId: environment.pgApiUrl + 'mra/get-agreement-type-by-mra-id',
    addAgreementType: environment.pgApiUrl + 'agreement-type/add-agreement-type',
    getAllAgreementType:  environment.pgApiUrl + 'agreement-type/get-all-agreement-type',
    getCountryByMraId:  environment.pgApiUrl + 'mra/get-country-by-mra-id',
    readMraListPublic: environment.pgApiUrl + 'mra/get-mra-list-public',
    getAllAccessType:  environment.pgApiUrl + 'access-type/get-all-access-type',
    getAllAgreementTypeWithSorting:  environment.pgApiUrl + 'agreement-type/get-all-agreement-type-sorted',
    deleteAgreementType: environment.pgApiUrl + 'agreement-type/delete',
    editAgreementType: environment.pgApiUrl + 'agreement-type/edit',
    deleteAttachment: environment.pgApiUrl + 'mra/delete-attachment-by-id',
  },
  event:{
    getEventTypes: environment.eventApiUrl + 'schedule/get-event-types',
    createUpdatetNewEvent: environment.eventApiUrl + 'schedule/create-or-update',
    submitNewEvent: environment.eventApiUrl + 'schedule/submit',
    deleteScheduleById: environment.eventApiUrl + 'schedule/delete-schedule-by-id/',
    getNewEvent: environment.eventApiUrl + 'schedule/get',
    getOrganizer: environment.eventApiUrl + 'schedule/get-organisers',
    reviewNewEvent: environment.eventApiUrl + 'schedule/review',
    createUpdateReport: environment.eventApiUrl + 'report/create-or-update',
    submitReport: environment.eventApiUrl + 'report/submit',
    getReport: environment.eventApiUrl + 'report/get',
    reviewReport: environment.eventApiUrl + 'report/review',
    postponeNewEvent: environment.eventApiUrl + 'schedule/postpone-or-cancel',
    getDashboard: environment.eventApiUrl + 'get-dashboard',
    downloadDashboard: environment.eventApiUrl + 'download-dashboard',
    getEventTypesPublic: environment.eventApiUrl + 'public-dashboard/get-event-types',
    getOrganizersPublic: environment.eventApiUrl + 'public-dashboard/get-organizers',
    getDashboardPublic: environment.eventApiUrl + 'public-dashboard/get-dashboard',
    getNewEventPublic: environment.eventApiUrl + 'public-dashboard/get-schedule',
    getReportPublic: environment.eventApiUrl + 'public-dashboard/get-report',
  },
  landingPageCounter:{
    readCertificatesCount: environment.commonServiceApiUrl + "common/total-certificates-count",
    readApprovedStpRegisterCount: environment.stpOperationApiUrl + "landing-page/total-approved-stp-register-count",
    readApprovedStpCoursesCount: environment.stpOperationApiUrl + "landing-page/total-approved-stp-courses-count",
    readApprovedStpCenterCount: environment.stpOperationApiUrl + "landing-page/total-approved-stp-center-count",
    readCSCount: environment.stpOperationApiUrl + "landing-page/total-cs-count",
  },
  industrySkillsCouncil: {
    getAllDivision: environment.tamApiUrl + 'isc-common/get-all-division',
    getDistrictsByDivisionId: environment.tamApiUrl + 'isc-common/get-districts-by-division-id',
    getUpazilasByDistrictId: environment.tamApiUrl + 'isc-common/get-upazilas-by-district-id',
    getCityCorporationsByDistrictId: environment.tamApiUrl + 'isc-common/get-city-corporations-by-district-id',
    getAllCityCorporation: environment.tamApiUrl + 'isc-common/get-all-city-corporation',
    getAllDisburseBy: environment.tamApiUrl + 'isc-common/get-all-disburse-by',
    getAllIscStatusType: environment.tamApiUrl + 'isc-common/get-all-isc-status-type',
    checkIfIscUserExists: environment.baseApiUrl + 'check-if-isc-user-exists',
    saveIndustrySkillsCouncil: environment.tamApiUrl + 'isc-admin/save-industry-skills-council',
    createIscUser: environment.baseApiUrl + 'create-isc-user',
    updateIscFocalPerson: environment.tamApiUrl + 'isc-admin/update-isc-focal-person',
    getIscBasicInformation: environment.tamApiUrl + 'isc-user/get-isc-basic-information',
    getIscFocalPersons: environment.tamApiUrl + 'isc-user/get-isc-focal-persons',
    saveTempFile: environment.tamApiUrl + 'isc-file/save-temp-file',
    deleteTempFile: environment.tamApiUrl + 'isc-file/delete-temp-file',
    saveIscFullBasicInformation: environment.tamApiUrl + 'isc-admin/save-isc-full-basic-information',
    saveIscBasicInformation: environment.tamApiUrl + 'isc-user/save-isc-basic-information',
    getVisionInfoByIscId: environment.tamApiUrl + 'isc-vision/get-vision-info-by-isc-id',
    saveIscVisionInformation: environment.tamApiUrl + 'isc-vision/save-isc-vision-information',
    getAllMissionByIscId: environment.tamApiUrl + 'isc-mission/get-all-mission-by-isc-id',
    getAllMissionInfoByIscId: environment.tamApiUrl + 'isc-mission/get-all-mission-info-by-isc-id',
    getMissionInfoById: environment.tamApiUrl + 'isc-mission/get-mission-info-by-id',
    saveIscMissionInformation: environment.tamApiUrl + 'isc-mission/save-isc-mission-information',
    deleteMissionInfoById: environment.tamApiUrl + 'isc-mission/delete-mission-info-by-id',
    updateMissionsSerialNo: environment.tamApiUrl + 'isc-mission/update-serial-no',
    getAllBoardOfDirectorsByIscId: environment.tamApiUrl + 'isc-board-of-director/get-all-board-of-directors-by-isc-id',
    getAllBoardOfDirectorsInfoByIscId: environment.tamApiUrl + 'isc-board-of-director/get-all-board-of-directors-info-by-isc-id',
    getBoardOfDirectorsInfoById: environment.tamApiUrl + 'isc-board-of-director/get-board-of-directors-info-by-id',
    saveIscBoardOfDirectorsInformation: environment.tamApiUrl + 'isc-board-of-director/save-isc-board-of-directors-information',
    deleteBoardOfDirectorsInfoById: environment.tamApiUrl + 'isc-board-of-director/delete-board-of-directors-info-by-id',
    updateBoardOfDirectorsSerialNo: environment.tamApiUrl + 'isc-board-of-director/update-serial-no',
    getAllRelatedAssociationsInfoByIscId: environment.tamApiUrl + 'isc-related-association/get-all-related-associations-info-by-isc-id',
    getRelatedAssociationsInfoById: environment.tamApiUrl + 'isc-related-association/get-related-associations-info-by-id',
    saveIscRelatedAssociationsInformation: environment.tamApiUrl + 'isc-related-association/save-isc-related-associations-information',
    deleteRelatedAssociationsInfoById: environment.tamApiUrl + 'isc-related-association/delete-related-associations-info-by-id',
    getAllSubSectorsByIscId: environment.tamApiUrl + 'isc-sub-sector/get-all-sub-sectors-by-isc-id',
    getAllSubSectorsInfoByIscId: environment.tamApiUrl + 'isc-sub-sector/get-all-sub-sectors-info-by-isc-id',
    getSubSectorInfoById: environment.tamApiUrl + 'isc-sub-sector/get-sub-sector-info-by-id',
    saveIscSubSectorInformation: environment.tamApiUrl + 'isc-sub-sector/save-isc-sub-sector-information',
    deleteSubSectorInfoById: environment.tamApiUrl + 'isc-sub-sector/delete-sub-sector-info-by-id',
    getAllDemandedOccupationsInfoByIscId: environment.tamApiUrl + 'isc-demanded-occupation/get-all-demanded-occupations-info-by-isc-id',
    getDemandedOccupationsInfoById: environment.tamApiUrl + 'isc-demanded-occupation/get-demanded-occupations-info-by-id',
    saveIscDemandedOccupationsInformation: environment.tamApiUrl + 'isc-demanded-occupation/save-isc-demanded-occupations-information',
    deleteDemandedOccupationsInfoById: environment.tamApiUrl + 'isc-demanded-occupation/delete-demanded-occupations-info-by-id',
    getAllExistFutureManpowerDemandInfoByIscId: environment.tamApiUrl + 'isc-exist-future-manpower-demand/get-all-exist-future-manpower-demand-info-by-isc-id',
    getExistFutureManpowerDemandInfoById: environment.tamApiUrl + 'isc-exist-future-manpower-demand/get-exist-future-manpower-demand-info-by-id',
    saveIscExistFutureManpowerDemandInformation: environment.tamApiUrl + 'isc-exist-future-manpower-demand/save-isc-exist-future-manpower-demand-information',
    deleteExistFutureManpowerDemandInfoById: environment.tamApiUrl + 'isc-exist-future-manpower-demand/delete-exist-future-manpower-demand-info-by-id',
    getAllJobOpportunitiesInfoByIscId: environment.tamApiUrl + 'isc-job-opportunity/get-all-job-opportunities-info-by-isc-id',
    getJobOpportunitiesInfoById: environment.tamApiUrl + 'isc-job-opportunity/get-job-opportunities-info-by-id',
    saveIscJobOpportunitiesInformation: environment.tamApiUrl + 'isc-job-opportunity/save-isc-job-opportunities-information',
    deleteJobOpportunitiesInfoById: environment.tamApiUrl + 'isc-job-opportunity/delete-job-opportunities-info-by-id',
    getAllApprenticeshipInfoByIscId: environment.tamApiUrl + 'isc-apprenticeship/get-all-apprenticeship-info-by-isc-id',
    getApprenticeshipInfoById: environment.tamApiUrl + 'isc-apprenticeship/get-apprenticeship-info-by-id',
    saveIscApprenticeshipInformation: environment.tamApiUrl + 'isc-apprenticeship/save-isc-apprenticeship-information',
    deleteApprenticeshipInfoById: environment.tamApiUrl + 'isc-apprenticeship/delete-apprenticeship-info-by-id',
    getAllAbledPwdMarginalizedInfoByIscId: environment.tamApiUrl + 'isc-abled-pwd-marginalized/get-all-abled-pwd-marginalized-info-by-isc-id',
    getAbledPwdMarginalizedInfoById: environment.tamApiUrl + 'isc-abled-pwd-marginalized/get-abled-pwd-marginalized-info-by-id',
    saveIscAbledPwdMarginalizedInformation: environment.tamApiUrl + 'isc-abled-pwd-marginalized/save-isc-abled-pwd-marginalized-information',
    deleteAbledPwdMarginalizedInfoById: environment.tamApiUrl + 'isc-abled-pwd-marginalized/delete-abled-pwd-marginalized-info-by-id',
    getAllActivityByIscId: environment.tamApiUrl + 'isc-activity/get-all-activity-by-isc-id',
    getAllActivityInfoByIscId: environment.tamApiUrl + 'isc-activity/get-all-activity-info-by-isc-id',
    getActivityInfoById: environment.tamApiUrl + 'isc-activity/get-activity-info-by-id',
    saveIscActivityInformation: environment.tamApiUrl + 'isc-activity/save-isc-activity-information',
    deleteActivityInfoById: environment.tamApiUrl + 'isc-activity/delete-activity-info-by-id',
    getAllBusinessPlanInfoByIscId: environment.tamApiUrl + 'isc-business-plan/get-all-business-plan-info-by-isc-id',
    getBusinessPlanInfoById: environment.tamApiUrl + 'isc-business-plan/get-business-plan-info-by-id',
    saveIscBusinessPlanInformation: environment.tamApiUrl + 'isc-business-plan/save-isc-business-plan-information',
    deleteBusinessPlanInfoById: environment.tamApiUrl + 'isc-business-plan/delete-business-plan-info-by-id',
    getAllAchievementInfoByIscId: environment.tamApiUrl + 'isc-achievement/get-all-achievement-info-by-isc-id',
    getAchievementInfoById: environment.tamApiUrl + 'isc-achievement/get-achievement-info-by-id',
    saveIscAchievementInformation: environment.tamApiUrl + 'isc-achievement/save-isc-achievement-information',
    deleteAchievementInfoById: environment.tamApiUrl + 'isc-achievement/delete-achievement-info-by-id',
    submitIscFullApplication: environment.tamApiUrl + 'isc-user/submit-isc-full-application',
    getAllNsdaIscList: environment.tamApiUrl + 'isc-admin/get-all-nsda-isc-list',
    getAllNsdaIscListExcel: environment.tamApiUrl + 'isc-admin/get-all-nsda-isc-list-excel',
    getAllIscDashboardCount: environment.tamApiUrl + 'isc-admin/get-all-isc-dashboard-count',
    publishIsc: environment.tamApiUrl + 'isc-admin/publish-isc',
    unpublishIsc: environment.tamApiUrl + 'isc-admin/unpublish-isc',
    getIscBasicInformationByIscId: environment.tamApiUrl + 'isc-admin/get-isc-basic-information-by-isc-id',
    getIscDetailInfoByIscId: environment.tamApiUrl + 'isc-user/get-isc-detail-info-by-isc-id',
    needCorrectionRepresentative: environment.tamApiUrl + 'isc-admin/need-correction-representative',
    submitApprovalRepresentative: environment.tamApiUrl + 'isc-admin/submit-approval-representative',
    rollbackScrutiny: environment.tamApiUrl + 'isc-admin/rollback-scrutiny',
    submitApprovalScrutiny: environment.tamApiUrl + 'isc-admin/submit-approval-scrutiny',
    rollbackMember: environment.tamApiUrl + 'isc-admin/rollback-member',
    submitApprovalMember: environment.tamApiUrl + 'isc-admin/submit-approval-member',
    approveMember: environment.tamApiUrl + 'isc-admin/approve-member',
    rejectMember: environment.tamApiUrl + 'isc-admin/reject-member',
    rollbackEC: environment.tamApiUrl + 'isc-admin/rollback-ec',
    approveEC: environment.tamApiUrl + 'isc-admin/approve-ec',
    rejectEC: environment.tamApiUrl + 'isc-admin/reject-ec',
    getRepresentativeIscList: environment.tamApiUrl + 'isc-user/get-representative-isc-list',
    getPublishedIscList: environment.tamApiUrl + 'isc-admin/get-published-isc-list',
    getPublishedIscDirectorListByIscId: environment.tamApiUrl + 'isc-admin/get-published-isc-director-list-by-isc-id',
    getIscRecommendationHistoryListByIscId: environment.tamApiUrl + 'isc-admin/get-isc-recommendation-history-list-by-isc-id',
    getIscApplicationHistoryListByIscId: environment.tamApiUrl + 'isc-admin/get-isc-application-history-list-by-isc-id',
    readSubSectorInfo: environment.tamApiUrl + 'isc-sub-sector/get-sub-sector-info',
  },
  specialBatch: {
    getSBTraineeOrCandidateInfo: environment.stpOperationApiUrl + 'specialBatch/get-special-batch-trainee-or-candidate-info',
    getSBTraineeInfo: environment.stpOperationApiUrl + 'specialBatch/get-special-batch-trainee-info-completed-but-not-yet-seated',
    getTraineeCandidateInfo: environment.stpOperationApiUrl + 'specialBatch/get-trainee-or-candidate-by-id/',
    createSpecialBatch: environment.stpOperationApiUrl + 'specialBatch/save-special-batch-assessment',
    updateSpecialBatch: environment.stpOperationApiUrl + 'specialBatch/update-special-batch-assessment/',
    submitSpecialBatchAssessment: environment.stpOperationApiUrl + "specialBatch/submit-special-batch-assessment",
    getSpecialBatchAssessmentInfo: environment.stpOperationApiUrl + 'specialBatch/get-special-batch-assessment-list',
    readSpecialBatchApplicationInfoById: environment.stpOperationApiUrl + 'specialBatch/get-special-batch-assessment-info-by-Id/',
    removeSpecialBatchTraineeOrCandidateFromAssessment: environment.stpOperationApiUrl + 'specialBatch/remove-trainee-or-candidate-from-assessment',
    getSpecialBatchAssessmentInfoAdmin: environment.commonServiceApiUrl + 'common/get-special-batch-assessment-admin-list',
    specialBatchPayment: environment.stpOperationApiUrl + 'assessment-payment/save-special-batch-offline-payment',
    submitSpecialBatchAssessmentSchedule: environment.stpOperationApiUrl + "specialBatch/submit-special-batch-assessment-schedule",
    saveSpecialBatchAssessmentSchedule: environment.stpOperationApiUrl + 'specialBatch/save-special-batch-assessment-schedule',
    getAssignedAssessmentInfo: environment.commonServiceApiUrl + 'common/get-special-batch-assigned-assessment-list',
    collectDataToDownloadCertificate: environment.stpOperationApiUrl + 'specialBatch/sb-collect-trainee-candidate-To-certificate-download/',
    getSpecialBatchAssessmentByApplicationId: environment.stpOperationApiUrl + "specialBatch/get-special-batch-assessment-by-applicationId/",
    readSpecialBatchAssessorInfoById: environment.stpOperationApiUrl + 'assessor/get-special-batch-assessors',
    needCorrection: environment.stpOperationApiUrl + "specialBatch/correction-request-special-batch-assessment"
  },
  researchAndStudies:{
    researchTypeInfo: environment.researchAndStudiesApiUrl + 'common/get-research-type',
    readLanguage: environment.researchAndStudiesApiUrl + 'common/get-language-info',
    readAccessType: environment.researchAndStudiesApiUrl + 'common/get-access-type',
    readRole: environment.researchAndStudiesApiUrl + 'common/get-role',
    // ministaryAndAgencyInfo: environment.stpRegistrationApiUrl + 'get-ministries-and-agency',
    commissionedByInfo: environment.researchAndStudiesApiUrl + 'common/get-commission-info',
    fundInfo: environment.researchAndStudiesApiUrl + 'common/get-fund-info',
    conductOrResearcherOrganizationTypeInfo: environment.researchAndStudiesApiUrl + 'common/get-conduct-or-researcher-organization-type',
    checkResearchTypeNameExist: environment.researchAndStudiesApiUrl + 'common/check-research-type-name-exist',
    checkResearchTypeCodeExist: environment.researchAndStudiesApiUrl + 'common/check-research-type-code-exist',
    saveResearchType: environment.researchAndStudiesApiUrl + 'common/save-research-type',
    deleteAuthor: environment.researchAndStudiesApiUrl + 'research-studies-main/delete-author/',
    saveResearchAnsStudiesInfo: environment.researchAndStudiesApiUrl + 'research-studies-main/save',
    updateResearchAnsStudiesInfo: environment.researchAndStudiesApiUrl + 'research-studies-main/update/',
    getAdminList: environment.researchAndStudiesApiUrl + 'research-studies-main/get-admin-list-info',
    getRSMainListForRelatedResearch: environment.researchAndStudiesApiUrl + 'research-studies-main/get-rs-main-list-info-for-related-research',
    getResearchStudiesDataByGlobalSearch: environment.researchAndStudiesApiUrl + 'research-studies-main/get-research-studies-data-by-global-search',
    conductOrResearcherOrganizationInfo: environment.researchAndStudiesApiUrl + 'common/get-conduct-researcher-organization-list-info',
    getPublicList: environment.researchAndStudiesApiUrl + 'research-studies-main/get-public-list-info',
    getExternalUsersList: environment.researchAndStudiesApiUrl + 'research-studies-main/get-external-users-list-info',
    getIscUsersList: environment.researchAndStudiesApiUrl + 'research-studies-main/get-isc-users-list-info',
    getDetailsInfo: environment.researchAndStudiesApiUrl + 'research-studies-main/get-research-and-studies-main-info-by-id/',
    getAuthorInfo: environment.researchAndStudiesApiUrl + 'research-studies-main/get-author-info-by-id/',
    getResearchStudiesMainDetailsInfoById: environment.researchAndStudiesApiUrl + 'research-studies-main/get-research-studies-main-details-info-by-id',
    getResearchStudiesMainDetailsInfoByIdForPublicList: environment.researchAndStudiesApiUrl + 'research-studies-main/get-research-studies-main-details-info-by-id-for-public-list',
    submitDocument: environment.researchAndStudiesApiUrl + 'research-studies-main/submit',
    makePublishPublicOrPublishLimited: environment.researchAndStudiesApiUrl + 'research-studies-main/make-publish-public-or-publish-limited',
    deleteDocument: environment.researchAndStudiesApiUrl + 'research-studies-main/delete-document',
    deleteAttachment:   environment.researchAndStudiesApiUrl + 'research-studies-main/delete-attachment',
    saveTamIndustrySector: environment.researchAndStudiesApiUrl + 'common/save-tam-industrial-sector',
    saveRole: environment.researchAndStudiesApiUrl + 'common/save-role',
    saveConductedBy: environment.researchAndStudiesApiUrl + 'common/save-conducted-by',
    removeRelatedResearch: environment.researchAndStudiesApiUrl + 'common/remove-related-research',
    downloadRsReport:  environment.researchAndStudiesApiUrl + 'research-studies-main/get-admin-list-info-report',
    saveOpinion: environment.researchAndStudiesApiUrl + 'research-studies-main/save-opinion',
    getOpinionInfo: environment.researchAndStudiesApiUrl + 'research-studies-main/get-opinion-info-by-id',
    getHistoryLogInfo: environment.researchAndStudiesApiUrl + 'research-studies-main/history-log',

    demandSurvey:{
    readSkillLevel: environment.researchAndStudiesApiUrl + 'common/get-skill-level',
    readBnqfLevel: environment.researchAndStudiesApiUrl + 'common/get-bnqf-level',
    readEducationLevel: environment.researchAndStudiesApiUrl + 'common/get-education-level',
    readPotentialToEmployLevel: environment.researchAndStudiesApiUrl + 'common/get-potential-to-employ',
    readCompetencies: environment.researchAndStudiesApiUrl + 'common/get-competencies',
    save: environment.researchAndStudiesApiUrl + 'demand-survey/save-demand-survey',
    update: environment.researchAndStudiesApiUrl + 'demand-survey/update-demand-survey/',
    delete: environment.researchAndStudiesApiUrl + 'demand-survey/delete-demand-survey/',
    getDemandSurveyInfoById: environment.researchAndStudiesApiUrl + 'demand-survey/demand-survey-by-id/',
    getDemandSurveyInfoByRSMainId: environment.researchAndStudiesApiUrl + 'demand-survey/demand-survey-by-research-studies-main-id/',
    },
    recommendationProcess:{
    submitProcess: environment.researchAndStudiesApiUrl + 'recommendation-process/submit-recommendation-process',
    processRollBack: environment.researchAndStudiesApiUrl + 'recommendation-process/recommendation-process-roll-back',
    // readRollBackAndRecTypeInfo: environment.researchAndStudiesApiUrl + 'recommendation-process/recommendation-process-remarks-and-roll-back-info',
    },

    externalUser:{
    getExternalUserRegFormInformation: environment.researchAndStudiesApiUrl + 'common/get-external-user-reg-form-manager-info',
    saveOrUpdateExternalUserRegFormInformation: environment.researchAndStudiesApiUrl + 'common/save-or-update-external-user-reg-form-manager'
    }
  },

  commonService:{
    common:{
      readTamMains: environment.commonServiceApiUrl + 'common/tam-mains-all'
    }
  },

  finance:{
    savePreventedOrg: environment.financeApiUrl + 'circular/save-prevented-org',
    updatePreventedOrg: environment.financeApiUrl + 'circular/update-prevented-org/',
    saveApplicableOccupations: environment.financeApiUrl + 'circular/save-applicable-occupations',
    updateApplicableOccupations: environment.financeApiUrl + 'circular/update-applicable-occupations/',
    deleteAttachment:   environment.financeApiUrl + 'circular/delete-attachment',
    saveAttachments: environment.financeApiUrl + 'circular/save-attachments',
    updateAttachments: environment.financeApiUrl + 'circular/update-attachments/',
    circularApplicantTypeInfo: environment.financeApiUrl + 'common/get-applicant-type',
    circularOrganizationTypeInfo: environment.financeApiUrl + 'common/get-organization-type',
    saveCircularInfo: environment.financeApiUrl + 'circular/save-circular-info',
    updateCircularInfo: environment.financeApiUrl + 'circular/update-circular-info/',
    getCircularInfoList: environment.financeApiUrl + 'circular/get-circular-list',
    getCircularPublicListInfo: environment.financeApiUrl + 'circular/get-circular-list-public',
    deleteCircular: environment.financeApiUrl + 'circular/delete-circular',
    getCircularDataByGlobalSearch: environment.financeApiUrl + 'circular/get-circular-data-by-global-search',
    getCircularInfoById: environment.financeApiUrl + 'circular/get-circular-info-by-id',
    getCircularPublicInfoById: environment.financeApiUrl + 'circular/get-circular-public-info-by-id',
    removeCirPreOrg: environment.financeApiUrl + 'circular/remove-circular-prevented-org',
    removeCirAppOccupations: environment.financeApiUrl + 'circular/remove-circular-applicable-occupations',
    submitCircular: environment.financeApiUrl + 'circular/submit-circular',
    updateCircularExpenseSectorHead: environment.financeApiUrl + 'circular/update-cir-expense-sector-head/',
    saveCircularExpenseSectorHead: environment.financeApiUrl + 'circular/save-cir-expense-sector-head',
    getCircularPreventedOrgInfoByStpMainIdAndCircularId: environment.financeApiUrl + 'circular/get-circular-prevented-org-info-by-stp-main-id-and-circular-id',
    downloadCircularReport:  environment.financeApiUrl + 'circular/get-circular-list-report',
    getApplicationStatusList: environment.financeApiUrl + 'common/get-application-status-list',
    getCircularDDList: environment.financeApiUrl + 'common/get-circular-dd-list',
    getApplicationIdDDList: environment.financeApiUrl + 'common/get-application-dd-list',
    getRecommendationStatusList: environment.financeApiUrl + 'common/get-recommendation-status-list',

    stpInformation:{
      getStpInfoByCircularIdAndStpMainId: environment.financeApiUrl + 'stp-info/get-stp-info-by-circular-id-and-stp-main-id',
      getStpMainInfo: environment.stpRegistrationApiUrl + 'get-stp-user-info',
      getStpInfrastructureInfoByStpMainId: environment.stpRegistrationApiUrl + 'registration/get-stp-infrastructure-info-by-stp-main-id',
      getStpOwnershipTypeById: environment.stpRegistrationApiUrl + 'ownership-type',
      saveStpInfo: environment.financeApiUrl + 'stp-info/save-stp-info',
      updateStpInfo: environment.financeApiUrl + 'stp-info/update-stp-info'
    },
    proposedTrainingProgram:{
      getProposedTrainingProgramInfoById: environment.financeApiUrl + 'proposed-training-program-info/get-proposed-training-program-info-by-id',
      getProposedTrainingProgramList: environment.financeApiUrl + 'proposed-training-program-info/get-proposed-training-program-list',
      saveGeneralCourseInfo: environment.financeApiUrl + 'proposed-training-program-info/save-general-course-info',
      saveProposedTrainingProgramInfo: environment.financeApiUrl + 'proposed-training-program-info/save-proposed-training-program-info',
      deleteProposedTrainingProgramInfoById: environment.financeApiUrl + 'proposed-training-program-info/delete-proposed-training-program-info-by-id',
      getProposedTrainingProgramInfoByTamMainIdAndFinanceMainId: environment.financeApiUrl + 'proposed-training-program-info/get-proposed-training-program-info-by-tam-main-id-and-finance-main-id'
    },
    proposedCourseInstructor:{
      getTrainerListByStpMainId: environment.stpOperationApiUrl + 'trainer/get-trainer-list-by-stp-main-id',
      getProposedCourseInstructorInfoById: environment.financeApiUrl + 'proposed-course-instructor-info/get-proposed-course-instructor-info-by-id',
      getProposedCourseInstructorList: environment.financeApiUrl + 'proposed-course-instructor-info/get-proposed-course-instructor-info-list',
      getProposedCourseInstructorListByFinanceMain: environment.financeApiUrl + 'proposed-course-instructor-info/get-proposed-course-instructor-info-list-by-finance-main',
      saveProposedCourseInstructorInfo: environment.financeApiUrl + 'proposed-course-instructor-info/save-or-update-proposed-course-instructor-info',
      deleteProposedCourseInstructorInfoById: environment.financeApiUrl + 'proposed-course-instructor-info/delete-proposed-course-instructor-info-by-id'
    },
    foreignLanguageTraining:{
      getForeignLanguageTrainingInfoById: environment.financeApiUrl + 'foreign-language-training-info/get-foreign-language-training-info-by-id',
      getForeignLanguageTrainingList: environment.financeApiUrl + 'foreign-language-training-info/get-foreign-language-training-info-list',
      saveForeignLanguageTrainingInfo: environment.financeApiUrl + 'foreign-language-training-info/save-or-update-foreign-language-training-info',
      deleteForeignLanguageTrainingInfoById: environment.financeApiUrl + 'foreign-language-training-info/delete-foreign-language-training-info-by-id'
    },
    organizationPastInfo:{
      getOrganizationPastInfoByFinancingMainId: environment.financeApiUrl + 'organization-past-info/get-organization-past-info-by-financing-main-id',
      saveOrganizationPastInfo: environment.financeApiUrl + 'organization-past-info/save-or-update-organization-past-info'
    },
    trainingProgramImplPlan:{
      getTrainingProgramImplPlanInfoByTamMainIdAndFinanceMainId: environment.financeApiUrl + 'training-program-impl-plan-info/get-training-program-impl-plan-info-by-tam-main-id-and-finance-main-id',
      getTrainingProgramImplPlanInfoById: environment.financeApiUrl + 'training-program-impl-plan-info/get-training-program-impl-plan-info-by-id',
      getTrainingProgramImplPlanList: environment.financeApiUrl + 'training-program-impl-plan-info/get-training-program-impl-plan-info-list',
      getTrainingProgramImplPlanListByFinanceMain: environment.financeApiUrl + 'training-program-impl-plan-info/get-training-program-impl-plan-info-list-by-finance-main',
      saveTrainingProgramImplPlanInfo: environment.financeApiUrl + 'training-program-impl-plan-info/save-or-update-training-program-impl-plan-info',
      deleteTrainingProgramImplPlanInfoById: environment.financeApiUrl + 'training-program-impl-plan-info/delete-training-program-impl-plan-info-by-id'
    },
    ongoingTrainingInfo:{
      getOngoingTrainingInfoById: environment.financeApiUrl + 'ongoing-training-info/get-ongoing-training-info-by-id',
      getOngoingTrainingInfoList: environment.financeApiUrl + 'ongoing-training-info/get-ongoing-training-info-list',
      saveOngoingTrainingInfo: environment.financeApiUrl + 'ongoing-training-info/save-or-update-ongoing-training-info',
      deleteOngoingTrainingInfoById: environment.financeApiUrl + 'ongoing-training-info/delete-ongoing-training-info-by-id',
      getOngoingTrainingInfoByFinanceMainId: environment.financeApiUrl + 'ongoing-training-info/get-ongoing-training-info-by-finance-main-id'
    },
    proposedBudgetStatementInfo:{
      getProposedBudgetStatementInfoByTamMainIdAndFinanceMainId: environment.financeApiUrl + 'budget/get-proposed-budget-statement-info-by-tam-main-id-and-finance-main-id',
      getProposedBudgetStatementInfoList: environment.financeApiUrl + 'proposed-budget-statement-info/get-proposed-budget-statement-info-list',
      getProposedBudgetStatementInfoListByFinanceMain: environment.financeApiUrl + 'proposed-budget-statement-info/get-proposed-budget-statement-info-list-by-finance-main',
      getProposedBudgetStatementInfoById: environment.financeApiUrl + 'budget/get-proposed-budget-statement-info-by-id',
      saveProposedBudgetStatementInfo: environment.financeApiUrl + 'budget/save-or-update-budget',
      deleteProposedBudgetStatementInfoById: environment.financeApiUrl + 'budget/delete-budget-by-id',
      checkAllProposedBudgetStatementInfoEntryCompletedByFinanceMain: environment.financeApiUrl + 'proposed-budget-statement-info/check-all-proposed-budget-statement-info-entry-completed-by-finance-main'
    },
    financialInfo:{
      getFinancialInformationByFinanceMainId: environment.financeApiUrl + 'financial-info/get-financial-info-by-finance-main-id',
      saveFinancialInfo: environment.financeApiUrl + 'financial-info/save-or-update-financial-info'
    },
    capacityAndPrevExpInfo:{
      getCapacityAndPrevExpInfoByFinanceMainId: environment.financeApiUrl + 'capacity-and-prev-experience-info/get-capacity-and-prev-experience-info-by-finance-main-id',
      saveCapacityAndPrevExperienceInfo: environment.financeApiUrl + 'capacity-and-prev-experience-info/save-or-update-capacity-and-prev-experience-info'
    },
    truthDeclarationSignSealInfo:{
      getTruthDeclarationSignSealByFinanceMainId: environment.financeApiUrl + 'truth-declaration-sign-seal/get-truth-declaration-sign-seal-by-finance-main-id',
      saveTruthDeclarationSignSealInfo: environment.financeApiUrl + 'truth-declaration-sign-seal/save-or-update-truth-declaration-sign-seal'
    },
    financingMain:{
      checkFinancingMainByCircularId: environment.financeApiUrl + 'financing-main/check-financing-main-by-circular-id',
      getFinancingMainByCircularIdAndStpMainId: environment.financeApiUrl + 'financing-main/get-financing-main-by-circular-id-and-stp-main-id',
      submitFinancingMain: environment.financeApiUrl + 'financing-main/submit-financing-main',
      checkApplicationSubmissionValidation: environment.financeApiUrl + 'financing-main/check-application-submission-validation',
      getAllFinancingMainByStpMainId: environment.financeApiUrl + 'financing-main/get-all-financing-main-by-stp-main-id',
      getFinancingApplicationListAdmin: environment.financeApiUrl + 'financing-main/get-financing-application-list-admin',
      getFinancingApplicationListNhrdf: environment.financeApiUrl + 'financing-main/get-financing-application-list-nhrdf',
      getFinancingApplicationNotificationByStpMainId: environment.financeApiUrl + 'financing-main/get-financing-application-notification-by-stp-main-id',
      getFinancingMainInfoByFinanceMainId: environment.financeApiUrl + 'financing-main/get-financing-main-info-by-finance-main-id',
      getApplicationHistoryListByFinanceMainId: environment.financeApiUrl + 'financing-main/get-application-history-list-by-finance-main-id'
    },
    financingApproval:{
      getOrganizationOverallPastInfoByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-organization-overall-past-info-by-finance-main-id',
      getProposedTrainingProgramOverallBudgetInfoByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-proposed-training-program-overall-budget-info-by-finance-main-id',
      getNsdaLevel1ApprovalInfoByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-nsda-level-1-approval-info-by-finance-main-id',
      saveNsdaLevel1ApprovalInfo: environment.financeApiUrl + 'financing-approval/save-nsda-level-1-approval-info',
      submitNsdaLevel1ApprovalInfo: environment.financeApiUrl + 'financing-approval/submit-nsda-level-1-approval-info',
      requestForCorrection: environment.financeApiUrl + 'financing-approval/request-for-correction',
      getNsdaLevel1ApprovalInfoForNsdaLevel2ByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-nsda-level-1-approval-info-for-nsda-level-2-by-finance-main-id',
      getNsdaLevel2ApprovalInfoByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-nsda-level-2-approval-info-by-finance-main-id',
      saveNsdaLevel2ApprovalInfo: environment.financeApiUrl + 'financing-approval/save-nsda-level-2-approval-info',
      submitNsdaLevel2ApprovalInfo: environment.financeApiUrl + 'financing-approval/submit-nsda-level-2-approval-info',
      requestForRollback: environment.financeApiUrl + 'financing-approval/request-for-rollback',
      requestForCorrectionAttachmentList: environment.financeApiUrl + 'financing-approval/get-attachment-list',
      correctionRequiredDocuments: environment.financeApiUrl + 'financing-approval/get-correction-required-documents',
      updateAttachment: environment.financeApiUrl + 'financing-approval/update-attachment',
      submitAfterCorrection: environment.financeApiUrl + 'financing-approval/submit-after-correction',
      correctionApplicationResubmissionByFinanceMainId: environment.financeApiUrl + 'financing-approval/correction-application-resubmission-by-finance-main-id',
      getNsdaLevel2ApprovalInfoForNhrdfLevel1ByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-nsda-level-2-approval-info-for-nhrdf-level-1-by-finance-main-id',
      getNhrdfLevel1ApprovalInfoByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-nhrdf-level-1-approval-info-by-finance-main-id',
      saveNhrdfLevel1ApprovalInfo: environment.financeApiUrl + 'financing-approval/save-nhrdf-level-1-approval-info',
      submitNhrdfLevel1ApprovalInfo: environment.financeApiUrl + 'financing-approval/submit-nhrdf-level-1-approval-info',
      getNhrdfLevel1ApprovalInfoForNhrdfLevel2ByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-nhrdf-level-1-approval-info-for-nhrdf-level-2-by-finance-main-id',
      getNhrdfLevel2ApprovalInfoByFinanceMainId: environment.financeApiUrl + 'financing-approval/get-nhrdf-level-2-approval-info-by-finance-main-id',
      saveNhrdfLevel2ApprovalInfo: environment.financeApiUrl + 'financing-approval/save-nhrdf-level-2-approval-info'
    },
    resolution:{
      getResolutionList: environment.financeApiUrl + 'resolution/get-resolution-list/',
      getResolutionIndvList: environment.financeApiUrl + 'resolution/get-resolution-individual-list/',
      getFinanceMainList: environment.financeApiUrl + 'resolution/get-finance-main-list/',
      saveResolution: environment.financeApiUrl + 'resolution/save-resolution/',
      saveResolutionIndividual: environment.financeApiUrl + 'resolution/save-resolution-individual/',
      getResolutionsByFinanceMainId: environment.financeApiUrl + 'resolution/get-resolutions-by-finance-main-id',
    }
  },

  jasper:{
    onDownloadMainReport: environment.commonServiceApiUrl + 'report/generate-assessment-attendance-and-result-sheet',
    onDownloadMApprvdOccReport: environment.commonServiceApiUrl + 'report/approved-occupation-report'
  },

  division:{
    getDivisionById: environment.stpRegistrationApiUrl + 'division'
  },
  district:{
    getDistrictById: environment.stpRegistrationApiUrl + 'district'
  },
  upazila:{
    getUpazilaById: environment.stpRegistrationApiUrl + 'upazilla'
  },
  mra:{
    readCSCount: environment.tamApiUrl + "mra-landing-page-counter/total-cs-count",
    readCADCount: environment.tamApiUrl + "mra-landing-page-counter/total-cad-count",
    readCBLMCount: environment.tamApiUrl + "mra-landing-page-counter/total-cblm-count",
    readMraDescription: environment.pgApiUrl + "mra/mra-description",
    readRssFeedData: environment.pgApiUrl + "mra/get-rss-feed-data",
    saveMraInfo: environment.pgApiUrl + 'mra/save-mra',
    updateMraInfo: environment.pgApiUrl + 'mra/update-mra',
    getMraInfoById: environment.pgApiUrl + 'mra/get-mra-details-by-mra-id',
  }
};
